import React, {useState} from 'react';
import { makeStyles } from "@material-ui/core/styles";
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import Button from "../../../components/CustomButtons/Button.js";
import { useScript } from "../../../services/hooks";

const dialogStyles = makeStyles((theme) => ({
    dialogContent: {
        overflowX: 'hidden',
        overflowY: 'hidden',
    },
    dialogTitle: {
        display: 'flex',
        justifyContent: 'left',
        position: 'relative'
    },
    iframe: {
        height: "700px",
        width: "100%",
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        border: 0,
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
    },
    rowCallOut: {
        cursor: 'pointer',
        alignItems: 'center',
        display: 'inline-flex',
        color: '#ffffff',
        fontSize: '30px',
        lineHeight: '1.2',
        textAlign: 'center',
        textDecoration: 'none',
        fontWeight: 'bold',
        opacity: 1,
        transition: 'all .3s ease-in-out',
        "&:hover, &:focus": {
            color: "#FFFFFF",
            opacity: .65
        },
        "@media (min-width: 576px)": {
            fontSize: '55px',
        },
        '& svg': {
          fontSize: 30,
        //   marginRight: 10,
          "@media (min-width: 576px)": {
            fontSize: '55px',
          },
        }
    },

}));

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

function SimpleDialog(props) {
    const classes = dialogStyles();
    const { open, setOpen } = props;
  
    const handleClose = () => {
      setOpen(false);
    };

    // useScript(props.script);
  
    return (
        <Dialog
            fullWidth={true}
            TransitionComponent={Transition}
            maxWidth={"lg"}
            open={open}
            onClose={handleClose}
            aria-labelledby="max-width-dialog-title"
        >
            <DialogTitle id="max-width-dialog-title" >
                <Grid container direction="row" className={classes.dialogTitle}> 
                    <Grid item xs={6}>
                        <Typography variant={'h6'}>{props.buttonText}</Typography>
                    </Grid>
                    <Grid item xs={6} className={classes.closeButton}>
                        <Button color="levarButton" size="sm" onClick={handleClose}>X</Button>
                    </Grid>
                </Grid>
            </DialogTitle>
            <DialogContent className={classes.dialogContent}>
            { props.url && 
                <iframe
                    className={classes.iframe}
                    src={props.url}
                    title="Forms Frame"
                >
                    <p>Your browser does not support iframes.</p>
                </iframe>
            }
            </DialogContent>
        </Dialog>
    );
};

const useStyles = makeStyles((theme) => ({
    root: {
        background: '#4d4b50',
        transition: 'background .5s ease-in-out',
        textDecoration: 'none',
        cursor: 'pointer',
        '&:hover': {
            background: '#0099ba',
            // background: '#ff3451',
        },
        '& > a': {
            '&:hover': {
                textDecoration: 'none',
                '& $subTitle': {
                    // color: '#231f20',
                    color: '#ffffff',
                },
                '& $arrow > svg': {
                    // fill: '#231f20',
                    fill: '#ffffff',
                    marginLeft: '1.5em',
                },
                '& $logo_levar': {
                    // fill: '#fe6c81',
                    fill: '#33a7c0',
                }
            },
        }
    },
    paper: {
        position: 'relative',
        zIndex: 2,
        padding: '5em 0 6em 0',
        maxWidth: 990,
        overflow: 'hidden'
    },
    subTitle: {
        fontSize: 18,
        letterSpacing: 4,
        textTransform: 'uppercase', 
        fontWeight: 500,
        color: '#0099ba',
        // color: '#ff3451',
        paddingBottom: 10,
        transition: 'color .25s ease',
    },
    title: {
        fontSize: 64,
        [theme.breakpoints.down('xs')]:{
            fontSize: 42,
        },
        fontWeight: 600,
        lineHeight: 1,
        color: '#fff',
        margin: 0
    },
    icon: { maxWidth: 60, width: '100%' },
    arrow: {
        marginLeft: '-1.5rem',
        overflow: 'hidden',
        position: 'relative',
        transition: 'fill .5s ease',
        '& svg': {
            transition: "margin-left .25s ease, fill .25s ease",
            marginLeft: '1rem',
            fill: '#0099ba'
        }
    },
    logo_levar: {
        position: 'absolute',
        top: '10%',
        right: '10%',
        zIndex: 0,
        height: 340,
        fill: '#373638', // gray
        transition: 'fill .5s ease-in-out',
    }
}));
export default function FullWidthCTA(props) {
    const classes = useStyles();
    const [open, setOpen] = useState(false);
    // console.log(props)
    const openCal = () => { 
        // console.log("hi1");
        setOpen(true);
    };

    return (
        <div className={classes.root}>
            <Link onClick={openCal}>
                <Container style={{ position: 'relative', overflow: 'hidden' }}>
                    <div className={classes.paper}>
                        <Typography className={classes.subTitle} noWrap={true} gutterBottom>
                            {props.content.callout}
                        </Typography>
                        <Typography className={classes.title} gutterBottom>
                            {props.content.text}
                        </Typography>
                        <div className={classes.arrow}>
                            <svg data-v-31179afc="" width="80" height="57" viewBox="0 0 80 57" fillRule="evenodd" clipRule="evenodd" xmlns="http://www.w3.org/2000/svg">
                                <path data-v-31179afc="" d="M74.9721 40L57.0955 54.8081L58.625 56.625L80 38.7626L58.625 21L57.0931 22.8145L74.9745 37.625H0V40H74.9721Z"></path>
                            </svg>
                        </div>
                    </div>
                    <svg version="1.0" xmlns="http://www.w3.org/2000/svg" className={classes.logo_levar}
                         viewBox="0 0 7083.000000 7083.000000"
                        preserveAspectRatio="xMidYMid meet">
                        <metadata>
                        Created by potrace 1.15, written by Peter Selinger 2001-2017
                        </metadata>
                        <g transform="translate(0.000000,7083.000000) scale(0.100000,-0.100000)"
                        stroke="none">
                        <path d="M55438 58916 c-8460 -6553 -24394 -18893 -35408 -27423 l-20025
                        -15508 -3 -7994 c-2 -6535 0 -7992 11 -7988 7 3 9242 7152 20522 15889 11281
                        8736 27212 21073 35403 27417 l14892 11533 0 7994 c0 4397 -2 7994 -4 7994 -2
                        0 -6927 -5361 -15388 -11914z"/>
                        <path d="M29539 11648 c-3232 -2521 -7912 -6171 -10401 -8113 l-4525 -3530
                        10405 -3 c5722 -1 15083 -1 20803 0 l10399 3 -3583 2795 c-10295 8031 -17217
                        13430 -17220 13430 -2 0 -2647 -2062 -5878 -4582z"/>
                        </g>
                    </svg>
                </Container>
            </Link>
            <SimpleDialog 
                open={open} 
                setOpen={setOpen}                   
                buttonText={props.content.text} 
                url={props.content.callout_link} />
        </div>
    );
}


/*
import React from 'react';
import { makeStyles } from "@material-ui/core/styles";
import Grid from '@material-ui/core/Grid';
// import Paper from '@material-ui/core/Paper';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import icon from '../../../assets/images/email/step2.png';
// Other Librarys
import Pulse from 'react-reveal/Pulse';
// Images
// import texture from "../../../assets/images/home/stepTexture.png";

const useStyles = makeStyles((theme) => ({
    root: {
        // background: '#cccccc',
        paddingTop: '2em',
        paddingBottom: '2em'
    },
    paper: {
        padding: theme.spacing(2),
    },
    subTitle: { 
        fontSize: 16, 
        textTransform: 'uppercase', 
        fontWeight: 600,
        textAlign: 'center',
        transition: 'fill .25s ease,color .25s ease',
        fill: '#000',
    },
    title: { 
        fontSize: 64,
        fontWeight: 600,
        lineHeight: 1,
        textAlign: 'center',
    },
    buttonContainer: {
        display: 'flex',
        paddingTop: '20px',
        justifyContent: 'center',
    },
    button: {
        background: '#1398b7',
        color: 'white',
        '&:hover': {
            opacity: .65,
            background: '#1398b7'
        }
    },
    icon: { maxWidth: 60, width: '100%' },
    link: {
        color: '#ff3451',
        textDecoration: 'none',
        fontSize: 16,
        opacity: 1,
        transition: 'all .3s ease',
        '&:hover': {
            opacity: .65
        }
    }
}));

export default function FullWidthCTA(props) {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <Container className={classes.container}>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                            <div className={classes.paper}>
                                <Typography className={classes.title} gutterBottom>
                                    {props.content.text}
                                </Typography>
                                <div className={classes.buttonContainer}>
                                    <Pulse forever={true}>
                                        <Button className={classes.button} onClick={() => window.open(props.content.callout_link)}>
                                            {props.content.callout}
                                        </Button>
                                    </Pulse>
                                </div>
                            </div>
                    </Grid>
                </Grid>
            </Container>
        </div>
    );
}

*/