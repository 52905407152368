import React from 'react';
import { makeStyles } from "@material-ui/core/styles";
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Collapse from '@material-ui/core/Collapse';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import ListItemText from '@material-ui/core/ListItemText';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import Divider from "@material-ui/core/Divider";
import { Link } from "react-router-dom";
import styles from "../../../assets/jss/components/headerStyles.js";

const useStyles = makeStyles(styles);

export default function MobileCollapseMenu(props) {
    const { primary_text, sub_links } = props;
    const [open, setOpen] = React.useState(false);
    const classes = useStyles();

    const handleClick = () => {
        setOpen(!open);
    };

    return (
        <React.Fragment>
            <ListItem button onClick={handleClick} className={classes.mobile_main_link}>
                <ListItemText primary={primary_text} />
                { open ? <ExpandLess /> : <ExpandMore /> }
            </ListItem>
            <Collapse in={open} timeout="auto" unmountOnExit className={classes.mobile_sub_root}>
                <Divider />
                <List component="div" disablePadding>
                    {
                        sub_links.map((row, index) => {
                            return (
                                <ListItem key={index} button component={Link} to={row.to} className={classes.mobile_nested_link}>
                                    <ListItemText primary={row.text} />
                                    <ChevronRightIcon />
                                </ListItem>
                            )
                        })
                    }
                </List>
            </Collapse>
        </React.Fragment>
    )
}