import React from 'react';
import { makeStyles } from "@material-ui/core/styles";
import { useScript } from "../../services/hooks";
import texture from "../../assets/images/home/stepTexture.png"
import Zoom from 'react-reveal/Zoom';
import Fade from 'react-reveal/Fade';
import shopifyCallOut from '../../assets/icons/Shopify-App-Store-Badge-Final-Black.svg';

const styles = {
  calender: {
    display: 'flex',
    flexDirection: 'column',
    '@media (min-width: 870px)': { flexDirection: 'row', },
  },
  copy: {
    display: 'flex',
    flexDirection: 'column',
    zIndex: 9,
    padding: '0em 2em 0 0',
    '@media (min-width: 870px)': { padding: '4em 2em 0 0' },
    '@media (min-width: 1120px)': { padding: '4em 4em 0 0' },
    '&::before': {
      content: "''",
      position: 'absolute',
      left: 0,
      height: '4px',
      width: 0,
      background: '-webkit-linear-gradient(left, rgba(0,51,78,1) 0%, rgba(255,52,81,1) 100%)',
      '@media (min-width: 1120px)': { width: 0 },
      '@media (min-width: 1380px)': { width: '7%' },
    },
    '& h2': {
      fontSize: '3em',
      margin: 0,
      lineHeight: 1,
      '@media (min-width: 570px)': { lineHeight: '34px' },
    },
    '& p': {
      fontSize: '1.5em',
    }
  },
  calenderContent: {
    position: 'relative',
    '&::before': {
      content: "''",
      backgroundImage: `url(${texture})`,
      position: 'absolute',
      zIndex: 1,
      top: '50%',
      height: '90%',
      transform: 'translateY(-50%)',
      right: '20%',
      width: '300px',
      backgroundSize: 'contain',
      opacity: .75,
      display: 'none',
      '@media (min-width: 570px)': { width: '500px', right: '0', display: 'block' },
      '@media (min-width: 870px)': { width: '500px', right: '50%' }
    },
    '& .calendly-inline-widget': {
      width: '300px',
      height: '600px',
      zIndex: 9,
      boxShadow: '0px 0px 6px 4px #00000021',
      '@media (min-width: 570px)': { width: '500px' },
      '@media (min-width: 870px)': { width: '300px' },
      '@media (min-width: 960px)': { width: '300px' },
      '@media (min-width: 1050px)': { width: '400px' },
      '@media (min-width: 1120px)': { width: '500px' },
      '@media (min-width: 1220px)': { width: '600px' }
    }
  }
};

const useStyles = makeStyles(styles);

const Calendar = () => {
  const classes = useStyles();
  useScript("https://assets.calendly.com/assets/external/widget.js");

  return (
      <div className={classes.calender}>
        <Zoom cascade>
          <div className={classes.copy}>
            <h2>Get Started Today!</h2>
            <p>Schedule a quick call, and we’ll help you drive more revenue</p>
            <a href="https://apps.shopify.com/levar-final?utm_source=levarwebsitesales&utm_medium=shopifyappbutton&utm_campaign=findusonshopify" target="_blank" style={{ maxWidth: '300px' }} rel="noopener noreferrer">
              <img src={shopifyCallOut} alt="Shopify levAR App" />
            </a>
          </div>
        </Zoom>
        <Fade delay={2000}>
        <div className={classes.calenderContent}>
          <div className="calendly-inline-widget"
               data-url="https://calendly.com/d/dhj-gvd-qzm/levar-demo">
          </div>
        </div>
        </Fade>
      </div>
  );
};

export default Calendar;
