import React from "react";
import Tooltip from '@material-ui/core/Tooltip';
import salesInfoGraphic from '../../../assets/images/sales/salesInfoGraphic.jpg';
import circles from '../../../assets/images/sales/circles.png';
import arrow from '../../../assets/images/sales/arrow.png';
import viewBtn from '../../../assets/icons/3dlogo.png';
import Zoom from 'react-reveal/Zoom';
import Fade from 'react-reveal/Fade';
import Pulse from 'react-reveal/Pulse';
import CountUp from 'react-countup';
import Viewer from "../../../components/Viewer/Viewer.js";
import WebsiteDialog from "../../../components/Dialog/WebsiteDialog";
// const VIEWER_URL = process.env.REACT_APP_VIEWER_URL;

const ARCommerceContent = () => {

  return (
    <div className="ARCommerce-Content">
      <div className="RowOne page-row">
        <div className="RowContent">
          <Fade left>
          <section className="copy">
            <h2>There Are Just<br/>3 Simple Steps</h2>
            <div className="copyContent">
              <p>
                Between you and having live augmented reality models of your products.
              </p>
            </div>
          </section>
          </Fade>
          <Fade cascade>
          <section className="imagery">
            <img src={salesInfoGraphic} alt="levAR" />
          </section>
          </Fade>
          <Zoom cascade delay={1000}>
          <section className="list">
            <div className="listContent">
              <span>01.</span>
              <p>Send us 6 photos and the dimensions of your product (only 6 photos, that’s it!).</p>
            </div>
            <div className="listContent">
              <span>02.</span>
              <p>Review the AR model we’ve created for you.</p>
            </div>
            <div className="listContent">
              <span>03.</span>
              <p>Switch your AR products live with one click.</p>
            </div>
          </section>
          </Zoom>
        </div>
      </div>
      <div className="RowTwo page-row">
        <div className="RowContent">
          <div className="RowTop">
            <Fade left>
            <section className="title">
              <h2>Give Your Customers<br/>What They Want</h2>
            </section>
            </Fade>
            <Fade right>
            <section className="cta">
              <img src={viewBtn} alt="View levAR Example" />
              <Pulse forever={true}>
                <a className="Viewer-Link" href="https://viewer.levar.io/index.html?variant=37522545967291&origin=external" target="_blank" rel="noopener noreferrer">
                  Try It Out
                </a>
              </Pulse>
            </section>
            </Fade>
          </div>
          <div className="RowBottom">
            <section className="circles">
              <img src={circles} alt="View levAR Example" />
            </section>
            <section className="list">
              <Zoom cascade delay={1500}>
              <ul>
                <li><span>72%</span> of shoppers purchased items they didn’t plan to purchase as a result of AR.</li>
                <li><span>71%</span> of shoppers would visit a store more often if they offered AR.</li>
                <li><span>40%</span> of shoppers are willing to pay more for a product if they experience it through AR.</li>
              </ul>
              </Zoom>
            </section>
          </div>
        </div>
      </div>
      <div className="RowThree page-row">
        <div className="RowContent">
          <section className="imagery">
            <div className="LevAR-Viewer">
              <Viewer viewer_url={'https://d31qwy1wuvutay.cloudfront.net/ar/k3dtvbbt.glb'} />
              <Pulse forever={true}>
              <a className="Viewer-Link" href="https://viewer.levar.io/index.html?variant=31586495332386&origin=external" target="_blank" rel="noopener noreferrer">
                <Tooltip title="View in your space!" aria-label="add">
                <img src={viewBtn} alt="View levAR Example" />
                </Tooltip>
              </a>
              </Pulse>
            </div>
          </section>
          <section className="copy">
            <h2>Answer Their Most<br/>Important Questions</h2>
            <div className="list">
              <Zoom cascade>
              <ul>
                <li><span>01.</span> Will it fit in my space?</li>
                <li><span>02.</span> How will it look in my room?</li>
                <li><span>03.</span> What does it really look like?</li>
              </ul>
              </Zoom>
            </div>
          </section>
        </div>
      </div>
      <div className="RowFour page-row">
        <div className="RowContent">
          <div className="copy">
            <section className="title">
              <h2>Track + Measure<br/>Your ROI</h2>
            </section>
            <div className="copyContent">
              <p>
                With our powerful AR-Commerce Analytics
                dashboard, you can see exactly how
                your new shopping experience is
                working harder for you.
                <br/><br/>
                We’re here to help you build a better
                shopping experience, for both you
                and your customers.
              </p>
              <WebsiteDialog 
                buttonText={'Start Today'} 
                script={"https://assets.calendly.com/assets/external/widget.js"}
                url={"https://calendly.com/d/dhj-gvd-qzm/levar-demo"} 
              />
            </div>
          </div>
          <div className="box-container">
            <div className="box-content">
              <Zoom cascade delay={500}>
              <div className="box-left">
                <section className="box one">
                  <div className="arrow-container">
                    <img className="arrow down" src={arrow} alt="levAR" />
                  </div>
                  <div className="box-copy">
                    <h3>Return<br/>Rates</h3>
                    <h6><CountUp className="countup" duration={5} delay={1.5} end={40} /><span>%</span></h6>
                  </div>
                </section>
              </div>
              <div className="box-right">
                <section className="box two">
                  <div className="arrow-container">
                    <img className="arrow up" src={arrow} alt="levAR" />
                  </div>
                  <div className="box-copy">
                    <h3>Customer<br/>Engagement</h3>
                    <h6><CountUp className="countup" duration={5} delay={1.5} end={200} /><span>%</span></h6>
                  </div>
                </section>
                <section className="box three">
                  <div className="arrow-container">
                    <img className="arrow up" src={arrow} alt="levAR" />
                  </div>
                  <div className="box-copy">
                    <h3>Conversion<br/>Rates</h3>
                    <h6><CountUp className="countup" duration={5} delay={1.5} end={27} /><span>%</span></h6>
                  </div>
                </section>
              </div>
              </Zoom>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ARCommerceContent;
