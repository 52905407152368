import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import classNames from "classnames";
import Button from "../../../components/CustomButtons/Button.js";
import Calendar from "../../../components/Calendar/Calendar.js";
import Zoom from 'react-reveal/Zoom';
import rowOneBg from '../../../assets/images/email/row-one-bg.jpg';
import step1 from '../../../assets/images/email/step1.png';
import step2 from '../../../assets/images/email/step2.png';
import step3 from '../../../assets/images/email/step3.png';
import step4 from '../../../assets/images/email/step4.png';
import salesOne from '../../../assets/images/sales/sales-row-bg.jpg';

import styles from "../../../assets/jss/views/salesPageStyle.js";
const useStyles = makeStyles(styles);

const SalesContent = () => {
  const classes = useStyles();

  return (
    <div className={classes.main}>
      <section className={classes.topBar}></section>
      <div className={classes.pageContent}>
        <div className={classNames(classes.row_one)}>
          <div className={classes.rowContent}>
            <div className={classes.rowOneBackground} style={{ backgroundImage: `url(${rowOneBg})`}}>
              <Zoom delay={1500}>
              <section className={classes.cta}>
                <h3>Just One Click to View in AR</h3>
                <Button
                  color="levarButton"
                  size="md"
                  href="https://viewer.levar.io/index.html?variant=31602533040162&origin=external"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                View in ar
                </Button>
              </section>
              </Zoom>
            </div>
          </div>
        </div>
        <div className={classNames(classes.row_two)}>
          <div className={classes.rowContent}>
            <section className={classes.imagery}>
              <div className={classes.rowImage}>
                <img src={salesOne} alt="Email Page" />
              </div>
            </section>
            <section className={classes.listContent}>
              <h2>Simple Next Steps.</h2>
              <div className="list">
                <Zoom cascade>
                <section className={classes.listRow}>
                  <div className={classes.listIcon}>
                    <img src={step1} alt="Schedule a Call" />
                  </div>
                  <p>Schedule a Call for a free AR test.</p>
                </section>
                <section className={classes.listRow}>
                  <div className={classes.listIcon}>
                    <img src={step2} alt="Free AR" />
                  </div>
                  <p>We’ll make your first 3D Models for free.</p>
                </section>
                <section className={classes.listRow}>
                  <div className={classes.listIcon}>
                    <img src={step3} alt="Analytics" />
                  </div>
                  <p>Run your 3-month test and use our Analytics dashboard to measure AR’s Impact on sales.</p>
                </section>
                <section className={classes.listRow}>
                  <div className={classes.listIcon}>
                    <img src={step4} alt="3D Models" />
                  </div>
                  <p>If you dont see the value, no worries. We’ll even let you keep your 3D models.</p>
                </section>
                </Zoom>
              </div>
            </section>
          </div>
        </div>
        <div className={classNames(classes.row_three)}>
          <div className={classes.rowContent}>
            <Calendar />
          </div>
        </div>
      </div>
    </div>
  );
}

export default SalesContent;
