import React from 'react';

const BlogLink = (props) => {
  return (
    <div className="BlogLink">
      <div className="BlogLink-Image" style={{ backgroundImage: `url(${props.featured_image})` }}>
      </div>
      <div className="BlogLink-Copy">
        <h2>{props.title}</h2>
        <p>{props.summary}</p>
      </div>
    </div>
  )
}

export default BlogLink;
