import React from 'react';
import Button from "../../../components/CustomButtons/Button.js";
import Tooltip from '@material-ui/core/Tooltip';
import Pulse from 'react-reveal/Pulse';

import stepOne from '../../../assets/images/home/stepOne.jpg';
import stepTwo from '../../../assets/images/home/stepTwo.png';
import stepThree from '../../../assets/images/home/step3new.jpg';
import viewBtn from '../../../assets/icons/3dlogo.png';
import shopifyCallOut from '../../../assets/icons/Shopify-App-Store-Badge-Final-White.svg';

const FeatureSteps = () => {
  return (
    <div className="FeatureSteps">
      <div className="Step Left">
        <div className="StepContent">
          <section className="featureLeft imagery">
            <div className="stepImage">
              <img className="stepCallOut" src={stepOne} alt="levAR" />
              <div className="stepLabel"><span>01.</span></div>
                <Pulse forever={true}>
                <a className="Viewer-Link" href="https://viewer.levar.io/index.html?variant=37522545967291&origin=external" target="_blank" rel="noopener noreferrer">
                  <Tooltip title="View in your space!" aria-label="add">
                  <img src={viewBtn} alt="View levAR Example" />
                  </Tooltip>
                </a>
                </Pulse>
            </div>
          </section>
          <section className="featureRight copy">
            <h2>Augmented Reality that actually looks real</h2>
            <div className="copyContent">
              <p>
                Send us photos & our in-house design team
                will bring it to life in just a few days.
                <br/><br/>
                The digital versions of your products are always built to scale;
                When someone uses AR to view your product,
                they will see exactly how it looks in their space.
              </p>
              <Button
                color="levarButton"
                size="md"
                href="/blog/posts/levar-asset-creation-overview"
              >
                HOW OUR MODELING COMPARES
              </Button>
            </div>
          </section>
        </div>
      </div>
      <div className="Step Right">
        <div className="StepContent">
          <section className="featureLeft copy">
            <h2>1-Click Shopify<br/>AR Integration</h2>
            <div className="copyContent">
              <p>
                levAR offers a seamless ecommerce integration
                with your shopify store and marketing channels.
                <br/><br/>
                No changes to your store’s look and feel,
                just a more powerful and engaging shopping experience.
              </p>
              <Button
                color="levarButton"
                size="md"
                href="https://apps.shopify.com/levar-final"
                target="_blank"
                rel="noopener noreferrer"
              >
                Try Now!
              </Button>
            </div>
          </section>
          <section className="featureRight imagery">
            <div className="stepImage">
              <a className="callOutBtn" href="https://apps.shopify.com/levar-final?utm_source=levarwebsitehome&utm_medium=shopifyappbutton&utm_campaign=findusonshopify" target="_blank" rel="noopener noreferrer">
                <img src={shopifyCallOut} alt="Shopify levAR App" />
              </a>
              <img className="stepCallOut" src={stepTwo} alt="levAR" />
              <div className="stepLabel"><span>02.</span></div>
            </div>
          </section>
        </div>
      </div>
      <div className="Step Left">
        <div className="StepContent">
          <section className="featureLeft imagery">
            <div className="stepImage">
              <img className="stepCallOut" src={stepThree} alt="levAR" />
              <div className="stepLabel"><span>03.</span></div>
            </div>
          </section>
          <section className="featureRight copy">
            <h2>Watch your sales &<br/>engagement grow</h2>
            <div className="copyContent">
              <p>
                Your products are ready to work harder for you going forward.
                <br/><br/>
                Measure levAR’s impact on sales and engagement with our analytics dashboard.
              </p>
              <Button
                color="levarButton"
                size="md"
                href="/ar-commerce"
              >
                HOW AR INCREASES YOUR ROI
              </Button>
            </div>
          </section>
        </div>
      </div>
    </div>
  )
}

export default FeatureSteps;
