import React from 'react';
import { makeStyles } from "@material-ui/core/styles";
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Button from "../../../components/CustomButtons/Button.js";

const useStyles = makeStyles((theme) => ({
    root: {
        background: '#cccccc',
        width: '100%'
    },
    container: {
        paddingTop: '6em',
        paddingBottom: '2em',
     },
     grid_container: {
        height: '100%'
     },
     paper: {
        padding: theme.spacing(2),
        height: '100%',
        position: 'relative',
        boxShadow: 'none',
        background: 'none'
     },
     title: {
        fontSize: 56,
        lineHeight: 1,
        margin: 0
     },
     copy: {
        // textAlign: 'center',
     },
     img_paper: {
        background: 'none',
        boxShadow: 'none'
     },
     feature_image: {
        width: '100%',
     }
}));

export default function HeroCustom(props) {
    const classes = useStyles();
    // console.log(props)
    
    return (
        <div className={classes.root}  style={{ background: props.content.background_color }}>
            <Container className={classes.container}>
                <Grid 
                    spacing={3}
                    container 
                    direction="row"
                    justify="center"
                    alignItems="center"
                    className={classes.grid_container}
                >
                    <Grid item xs={12} md={6} className={classes.item1}>
                        <Paper className={classes.paper}>
                            { props.content.title && <h1 className={classes.title}>{props.content.title}</h1> }
                            { props.content.copy && <p className={classes.copy}>{props.content.copy}</p> } 
                            { props.content.link_text && 
                                <Button
                                    color="levarButton"
                                    size="md"
                                    href={props.content.link_ref}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    {props.content.link_text}
                                </Button>
                            }
                        </Paper>
                    </Grid>
                    <Grid item xs={12} md={6} className={classes.item2}>
                        <Paper className={classes.img_paper}>
                            { props.content.image && <img src={props.content.image} className={classes.feature_image} alt={props.content.title} /> }
                        </Paper>
                    </Grid>
                </Grid>
            </Container>
        </div>
    )
}
