import React from 'react';
import Slideshow from "./Slideshow";

// import slideFamilyRoom from "../../../assets/images/home/hero-familyroom.jpg";
import slideBackyard from "../../../assets/images/home/hero-backyard.jpg";
import slideKitchen from "../../../assets/images/home/hero-kitchen.jpg";
import slideBedroom from "../../../assets/images/home/hero-bedroom.jpg";

const s = {
  imageContainer: "screenH fullW df"
};

const slides = [ slideBackyard, slideKitchen, slideBedroom ];

const HomeSlider = (props) => {
  return (
    <div className={s.imageContainer}>
      <div style={{ flex: 1 }}>
        <Slideshow slides={slides} setTitle={props.setTitle} clearTitle={props.clearTitle} />
      </div>
    </div>
  );
}

export default HomeSlider;
