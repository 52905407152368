import React from "react";
import Fade from 'react-reveal/Fade';
import Viewer from "../../../components/Viewer/Viewer.js";
import { makeStyles } from "@material-ui/core/styles";
import texture from "../../../assets/images/home/stepTexture.png";
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import GridItem from "../../../components/Grid/GridItem.js";
import GridContainer from "../../../components/Grid/GridContainer.js";
import EventIcon from '@material-ui/icons/Event';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import WebsiteDialog from '../../../components/Dialog/WebsiteDialog';


const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    color: theme.palette.text.secondary,
    background: `linear-gradient( to top, rgba(255,255,255, .65), rgba(255,255,255, .6)), url(${texture})`,
    flexDirection: 'column',
    justifyContent: 'flex-start',
    textAlign: 'left',
    width: '100%',
    '& h3': { margin: 0, fontSize: 25, color: '#1f1f1f' },
    '& ul': { margin: 0, paddingLeft: 25, color: '#1f1f1f' },
  },
  pricingBoxes: {
    position: "relative",
    zIndex: 9,
    width: '100%',
    maxWidth: '1300px',
    margin: 'auto'
  },
  gridItem: {
    display: 'flex',
    alignItems: 'stretch',
    '& ul': {

    }
  },
  box: {
    flex: 1,
    height: '300px',
    borderRadius: '3px',
    boxShadow: '1px 3px 6px 0px #15151540',
    position: 'relative',
    padding: '1em',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    background: `linear-gradient( to top, rgba(255,255,255, .65), rgba(255,255,255, .6)),url(${texture})`
  },
  rowCallOut: {
    alignItems: 'center',
    display: 'inline-flex',
    '& a': {
      color: '#ffffff',
      fontSize: '30px',
      lineHeight: '1.2',
      textAlign: 'center',
      display: 'flex',
      alignItems: 'center',
      textDecoration: 'none',
      fontWeight: 'bold',
      opacity: 1,
      transition: 'all .3s ease-in-out',
      "&:hover, &:focus": {
        color: "#FFFFFF",
        opacity: .65
      },
      "@media (min-width: 576px)": {
        fontSize: '55px',
      },
    },
    '& svg': {
      fontSize: 30,
      marginRight: 10,
      "@media (min-width: 576px)": {
        fontSize: '55px',
      },
    }
  }
}));

const PricingContent = () => {
  const classes = useStyles();
  return (
    <div className="Pricing-Content">
      <Grid container spacing={3} className={classes.pricingBoxes}>
        <Grid item xs={12} sm={6} md={4} className={classes.gridItem}>
          <Paper className={classes.paper}>
            <h3>Starter</h3>
            <ul>
              <li>Up to 10 AR Models Hosted</li>
              <li>Analytics</li>
              <li>Dynamic Links</li>
              <li>Unlimited Views</li>
            </ul>
          </Paper>
        </Grid>
        <Grid item xs={12} sm={6} md={4} className={classes.gridItem}>
          <Paper className={classes.paper}>
            <h3>Advanced</h3>
            <ul>
              <li>Up to 100 AR Models Hosted</li>
              <li>Analytics</li>
              <li>AR Model Volume Discount</li>
              <li>Social Integration</li>
              <li>Unlimited Views</li>
            </ul>
          </Paper>
        </Grid>
        <Grid item xs={12} sm={6} md={4} className={classes.gridItem}>
          <Paper className={classes.paper}>
            <h3>Enterprise</h3>
            <ul>
              <li>250+ Models Hosted</li>
              <li>Analytics</li>
              <li>AR Model Volume Discount</li>
              <li>Email & Print Integrations </li>
              <li>Dedicated CSM</li>
              <li>Unlimited Views</li>
            </ul>
          </Paper>
        </Grid>
      </Grid>
      <div className="RowCallOut">
        <GridContainer>
          <GridItem xs={12} sm={12} md={12} className={classes.rowCallOut}>
            <WebsiteDialog 
                  buttonText={''} 
                  button_type={'custom'}
                  size={'h3'}
                  custom_code={<><EventIcon /> Book A Demo <ArrowRightIcon /></>}
                  script={"https://assets.calendly.com/assets/external/widget.js"}
                  url={"https://calendly.com/d/dhj-gvd-qzm/levar-demo"} 
            />
          </GridItem>
        </GridContainer>
      </div>
      <div className="RowOne page-row">
        <div className="RowContent">
          <Fade left>
          <section className="copy">
            <h2>What is an AR Model?</h2>
            <div className="copyContent">
              <p>We’ve made AR models for everything from rugs, furniture, motorcycles, even an entire house!</p>
              <p>Every product and its variants are unique, therefore pricing per 3D model is variable in-order to meet our exacting in-house standards. We offer volume discounts depending on the number of assets you decide to make with us.</p>
              <em>To learn more about asset creation and pricing feel free to reach out to sales@levar.io or schedule a call.</em>
            </div>
          </section>
          </Fade>
          <section className="imagery">
            <Viewer viewer_url={'https://d31qwy1wuvutay.cloudfront.net/ar/k3dtvbbt.glb'} />
          </section>
        </div>
      </div>
    </div>
  );
}

export default PricingContent;
