import { container, subtitle } from "../material-react.js";
import watchBG from "../../images/home/watch.jpg"

const homePageStyle = {
  container: {
    zIndex: "12",
    color: "#FFFFFF",
    ...container
  },
  titleContent: {
    zIndex: 9,
    color: '#fff',
    position: 'absolute',
    padding: '0 2em',
    width: '100%',
    "@media (min-width: 768px)": {
      padding: '0 4em',
    },
  },
  heroTitle: {
    fontSize: '1.8em',
    fontWeight: '700',
    margin: '.5rem 0',
    lineHeight: 1,
    "@media (min-width: 420px)": {
      fontSize: '2em',
    },
    "@media (min-width: 576px)": {
      fontSize: '3em',
    },
    "@media (min-width: 768px)": {
      fontSize: '4em',
    },
    "@media (min-width: 992px)": {
      fontSize: '4.5em',
    },
  },
  subtitle: {
    ...subtitle,
    fontSize: "1rem",
    margin: 0,
    lineHeight: 1.4,
    "@media (min-width: 576px)": {
      fontSize: "1.1rem",
    },
    "@media (min-width: 768px)": {
      fontSize: "1.4rem",
    },
  },
  main: {
    background: "#FFFFFF",
    position: "relative",
    zIndex: "3"
  },
  gradientBar: {
    // background: 'rgb(0, 153, 186)',
    // background: '-webkit-linear-gradient(left, rgba(0,153,186,1) 0%, rgba(0,51,78,1) 50%, rgba(255,52,81,1) 100%)',
    // background: '-o-linear-gradient(left, rgba(0,153,186,1) 0%, rgba(0,51,78,1) 50%, rgba(255,52,81,1) 100%)',
    background: 'linear-gradient(to right, rgba(0,153,186,.90) 0%, rgba(0,51,78,.90) 50%, rgba(255,52,81,.90) 100%)',
    width: '100%',
    height: '15px',
  },
  featureRowGradient: {
    // background: 'rgb(0, 153, 186)',
    // background: `-webkit-linear-gradient(left, rgba(0,51,78,.95) 0%, rgba(255,52,81,.90) 100%), url(${watchBG})`,
    // background: `-o-linear-gradient(left, rgba(0,51,78,.95) 0%, rgba(255,52,81,.90) 100%), url(${watchBG})`,
    background: `linear-gradient(to right, rgba(0,51,78,.95) 0%, rgba(255,52,81,.90) 100%), url(${watchBG})`,
    width: '100%',
    height: '400px',
    backgroundSize: 'cover',
    padding: '20px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    "@media (min-width: 576px)": {
      height: '500px',
    },
  },
  buttonGroup: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    justifyContent: 'center',
    '& a': {
      margin: '1em',
    }
  },
  featureRowTitle: {
    color: '#ffffff',
    fontSize: '30px',
    lineHeight: '1.2',
    textAlign: 'center',
    margin: '10px 0',
    "@media (min-width: 576px)": {
      fontSize: '55px',
    },
  }
};

export default homePageStyle;
