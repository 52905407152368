import React from 'react';
import classNames from "classnames";
import { makeStyles } from "@material-ui/core/styles";
import ParallaxGradient from "../../components/Parallax/ParallaxGradient.js";
import Header from "../../components/Header/Header";
import SideArrow from "../../components/SideArrow";
import PageTitle from "../../components/PageTitle";
import ThreeBoxes from "./Sections/ThreeBoxes";
import ARCommerceContent from "./Sections/ARCommerceContent";
import styles from "../../assets/jss/views/arCommercePageStyle.js";
const useStyles = makeStyles(styles);

export default function ARCommerce() {
    const classes = useStyles();

    return (
        <div className="ARCommerce Page-Content">
            <Header color="transparent" fixed changeColorOnScroll={{ height: 150, color: "dark" }} />
            <SideArrow />
            <ParallaxGradient
                medium
                filter
                image={require("../../assets/images/sales/sales-bg.jpg")}
                >
                <PageTitle title={'What Makes AR So Powerful For Driving Sales?'} />
            </ParallaxGradient>
            <div className={classNames(classes.main)}>
                <ThreeBoxes />
                <ARCommerceContent />
            </div>
        </div>
    );
}
