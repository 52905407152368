import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Container from '@material-ui/core/Container';

const useStyles = makeStyles((theme) => ({
    container: {
        paddingTop: '4em',
        paddingBottom: '4em'
    },
    paper: {
        padding: theme.spacing(2),
        background: 'none',
        boxShadow: 'none'
    },
    item1: {
        order: 2,
        [theme.breakpoints.up('md')]: {
            order: 1,
        },
      },
    item2: {
        order: 1,
        [theme.breakpoints.up('md')]: {
            order: 2,
        },
    },
    title: {
        fontWeight: 700,
        lineHeight: 1.2,
        margin: 0,
        marginBottom: 20,
        fontSize: 42,
        [theme.breakpoints.down('xs')]:{
            fontSize: 32,
        },
        color: '#000000',
        "&:before": {
            content: "' '",
            height: 5,
            width: 57,
            position: 'absolute',
            marginTop: '-1.5rem',
            background: "#ff3451"
        }
    },
    text: {
        fontSize: 18,
        color: '#373f47',
        fontWeight: 400,
        lineHeight: 1.8
    },
    video_paper: {
        padding: "10px 10px 3px 10px"
    },
    iframeContainer: {
        overflowX: 'hidden',
        overflowY: 'hidden',
        height: '100%',
        width: '100%',
    },
    iframe: {
        minHeight: "450px",
        height: '100%',
        width: '100%',
        borderRadius: 3,
        border: 0
    }
}));

export default function VideoPlayer(props) {
    const classes = useStyles();

    return (
        <Container className={classes.container}>
            <Grid 
                spacing={3}
                container 
                direction="row"
                justify="center"
                alignItems="center"
            >
                <Grid item xs={12} md={6} className={classes.item1}>
                    <Paper className={classes.paper}>
                        { props.content.title && <h3 className={classes.title}>{props.content.title}</h3> }
                        { props.content.text && <p className={classes.text}>{props.content.text}</p> } 
                    </Paper>
                </Grid>
                <Grid item xs={12} md={6} className={classes.item2}>
                    <Paper className={classes.video_paper}>
                        <div className={classes.iframeContainer}>
                        { props.content.url &&
                            <iframe
                                className={classes.iframe}
                                src={props.content.url}
                                title="Video Player"
                                frameborder="0" 
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
                                allowfullscreen
                            >
                                <p>Your browser does not support iframes.</p>
                            </iframe>
                        }
                        </div>
                    </Paper>
                </Grid>
            </Grid>
        </Container>
    );
}

/*
<iframe 
    width="560" 
    height="315" 
    src="https://www.youtube.com/embed/bgnFA4vuK5g" 
    title="YouTube video player" 
    frameborder="0" 
    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
    allowfullscreen
></iframe>

*/

// return (
//     <Container className={classes.container}>
//         <Grid 
//             container 
//             direction="row"
//             justify="center"
//             alignItems="center"
//         >
//             <Grid item xs={12} md={6} className={classes.item1}>
//                 { props.content.viewer_position === 'right' &&
//                     <Paper className={classes.paper}>
//                         { props.content.title && <h3 className={classes.title}>{props.content.title}</h3> }
//                             { props.content.text && <p className={classes.text}>{props.content.text}</p> } 
//                             { props.content.button_text && 
//                                 <Button
//                                     color="levarButton"
//                                     size="md"
//                                     href={props.content.button_link}
//                                     target="_blank"
//                                     rel="noopener noreferrer"
//                                 >
//                                     {props.content.button_text}
//                                 </Button>
//                             }
//                     </Paper>
//                 }
//                 { props.content.viewer_position === 'left' &&
//                     <Paper className={classes.paper}>
//                         <div className={classes.iframeContainer}>
//                             <iframe
//                                 className={classes.iframe}
//                                 src={viewerURl}
//                                 title="Viewer"
//                             >
//                                 <p>Your browser does not support iframes.</p>
//                             </iframe>
//                         </div>
//                     </Paper>
//                 }
//             </Grid>
//             <Grid item xs={12} md={6} className={classes.item2}>
//             { props.content.viewer_position === 'left' && 
//                 <Paper className={classes.paper}>
//                     { props.content.title && <h3 className={classes.title}>{props.content.title}</h3> }
//                         { props.content.text && <p className={classes.text}>{props.content.text}</p> } 
//                         { props.content.button_text && 
//                             <Button
//                                 color="levarButton"
//                                 size="md"
//                                 href={props.content.button_link}
//                                 target="_blank"
//                                 rel="noopener noreferrer"
//                             >
//                                 {props.content.button_text}
//                             </Button>
//                         }
//                 </Paper>
//             }
//             { props.content.viewer_position === 'right' && 
//                 <Paper className={classes.paper}>
//                     <div className={classes.iframeContainer}>
//                         <iframe
//                             className={classes.iframe}
//                             src={viewerURl}
//                             title="Viewer"
//                         >
//                             <p>Your browser does not support iframes.</p>
//                         </iframe>
//                     </div>
//                 </Paper>
//             }
//             </Grid>
//         </Grid>
//     </Container>
// );