import * as actions from '../actions/index';
import { fetchSetKlaviyo } from '../../services/fetchData';

export const setKlaviyoEmail = (email) => {
    return async (dispatch) => {
      try {
        dispatch(actions.setLoading(true));
        const data = {"userEmail": email};
        await fetchSetKlaviyo(data);
        dispatch(actions.setLoading(false));
      } catch (error) {
        dispatch(actions.setError(error.message));
      }
    }
  }