import React, { useState } from 'react';
import classNames from "classnames";
import { makeStyles } from "@material-ui/core/styles";
import Header from "../../components/Header/Header";
import ParallaxHome from "../../components/Parallax/ParallaxHome.js";
import Button from "../../components/CustomButtons/Button.js";
import TypeWriter from './Sections/TypeWriter.js';
import FeatureSteps from './Sections/FeatureSteps.js';
import FeatureRowGradient from './Sections/FeatureRowGradient.js';
import HomeSlider from './Sections/HomeSlider';
import Video from './Sections/Video';
import styles from "../../assets/jss/views/homePageStyle.js";
import VideocamIcon from '@material-ui/icons/Videocam';

const { REACT_APP_DEMO_STORE_URL } = process.env;
const useStyles = makeStyles(styles);
let msgs = ["Backyard?", "Kitchen?", "Bedroom?"];

const Home = () => {
  const classes = useStyles();
  const [currText, setText] = useState('');
  const [clearText, setClearText] = useState(0)
  const [videoOpen, setVideoOpen] = useState(false)

  const setTitle = (currentId) => {
    setText(msgs[currentId])
  };

  const clearTitle = () => {
    let num = clearText + 1
    setClearText(num);
  };

  const handleVideoOpen = (status) => {
    setVideoOpen(status)
  }

  return (
    <div className="Home Page-Content">
      <Header
        color="transparent"
        fixed
        changeColorOnScroll={{
          height: 150,
          color: "dark"
        }}
      />
      <ParallaxHome filter>
        <div className={classes.titleContent}>
          <p className={classes.subtitle}>
            Creating powerful digital experiences<br/>
            to answer every shoppers’ question:
          </p>
          <TypeWriter currText={currText} clearText={clearText} />
          <Button
            color="levarButton"
            size="md"
            href={REACT_APP_DEMO_STORE_URL}
            target="_blank"
            rel="noopener noreferrer"
          >
            Try the demo
          </Button>
          <Button
            color="levarButton"
            size="md"
            style={{ marginLeft: '1em' }}
            onClick={() => handleVideoOpen(true)}
          >
            Watch Demo <VideocamIcon style={{ fontSize: '1rem' }}/>
          </Button>
        </div>
        <HomeSlider setTitle={setTitle} clearTitle={clearTitle} />
      </ParallaxHome>
      <div className={classNames(classes.main)}>
        <div className={classes.gradientBar}></div>
        <FeatureSteps />
        <FeatureRowGradient />
        { videoOpen === true && <Video youtubeId={'wCx6fsYPg3I'} videoOpen={videoOpen} handleVideoOpen={handleVideoOpen} />}
      </div>
    </div>
  )
}

export default Home;
