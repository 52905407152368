import React, {useState} from 'react';
import { useDispatch } from 'react-redux';
// Thunks
import { setKlaviyoEmail } from '../../../redux/thunks/setData';
// @material-ui/core
import { makeStyles } from "@material-ui/core/styles";
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';

const useStyles = makeStyles((theme) => ({
    container: {
        paddingTop: '2em',
        paddingBottom: '2em',
        [theme.breakpoints.up('md')]: {
            paddingLeft: '15em',
            paddingRight: '15em'
        },
    },
    content: {
        textAlign: 'left',
    },
    textField: {

        width: '300px',
        paddingRight: '10px',
    },
    button: {
        height: '56px',
        background: '#1398b7',
        // marginTop: '10px',
        color: '#FFF',
        fontWeight: 600
    },
    title: {
        fontWeight: 600,
    },
}));

export default function Newsletter(props) {
    const classes = useStyles();
    const dispatch = useDispatch();
    const [email, setEmail] = useState('');


    const handleSubmit = async() => {
        await dispatch(setKlaviyoEmail(email));
    };

    return (
        <Container className={classes.container}>
            <Grid container direction="column" justify="center" alignItems="center" spacing={3}>
                <Grid item xs={12} className={classes.content}>
                    <Typography className={classes.title} variant={"h6"} gutterBottom>
                        {props.content.text}
                    </Typography>
                    
                    <TextField 
                        className={classes.textField} 
                        variant="outlined" 
                        label={props.content.email}
                        value={email}
                        onChange={(event)=> setEmail(event.target.value)}
                    >
                        
                    </TextField>
                    <Button className={classes.button} onClick={()=> handleSubmit()}>
                        {props.content.subscribe_button_text}
                    </Button>
                </Grid>
            </Grid>
        </Container>
    )
}