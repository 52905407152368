import React from 'react';
import routes from "./routes";
import { Switch, Redirect, Route } from "react-router-dom";
import Footer from "./components/Footer/Footer";

const App = () => {

  return (
    <div className="App">
      <Switch>
        { routes.map((prop, key) => {
            if(prop.redirect){
              return <Redirect from={prop.path} to={prop.to} key={key} />;
            }
            return (
              <Route path={prop.path} component={prop.component} key={key} />
            )
        })}
      </Switch>
      <Footer />
    </div>
  );
}

export default App;


    // <div className="App">
    //   <Switch>
    //   {
    //     routes.map((prop, key) => {
    //       if (prop.redirect)
    //         return <Redirect from={prop.path} to={prop.to} key={key} />;
    //       return (
    //         <Route
    //           path={prop.path}
    //           key={key}
    //           render={props => <prop.component {...props} />}
    //         />
    //       );
    //     })
    //   }
    //   </Switch>
    //   <Footer />
    // </div>
