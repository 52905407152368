import React, { useEffect, useRef } from "react";
import { useThree, useFrame } from "react-three-fiber";
// import { useViewerStore } from '../../zustand';
// import useKeyPress from '../../utility/hooks/useKeyPress';

const Camera = (props) => {
  const camera = useRef();
  const { size, setDefaultCamera } = useThree();
  // const reset_key = useKeyPress("f");
  // const model = useViewerStore(state => state.model);

  useEffect(() => {
    let model = { size: { x: 0.5611930931440647, y: 0.8727187514305121, z: 0.6210610003686308 } }
    const { x, y, z } = model.size;
    //
    let d = 10000;
    let posZ, posX, posY;
    posZ = posX = posZ = 0;
    //
    posZ = ((y * d + z * d + x * d) / d) * 1.3;
    //
    if (((y * d) > (x * d / 10)) && ((y * d) > (z * d / 10))) {
      posY = y / 1.2;
    } else {
      posY = ((z * d * x * d) / (d * d));
    };

    camera.current.fov = 54;
    camera.current.updateProjectionMatrix((camera.current.position.x = posX));
    camera.current.updateProjectionMatrix((camera.current.position.y = posY));
    camera.current.updateProjectionMatrix((camera.current.position.z = posZ));
  }, [])

  useEffect(() => void setDefaultCamera(camera.current), [setDefaultCamera]); // Make the camera known to the system
  useFrame(() => camera.current.updateMatrixWorld()); // Update it every frame

  return (
    <React.Fragment>
      <perspectiveCamera
        {...props}
        ref={camera}
        aspect={size.width / size.height}
        name="levar_camera"
        onUpdate={self => self.updateProjectionMatrix()}
      />
    </React.Fragment>
  );
}

export default Camera;
