import {
  levarBtnColor
} from "../material-react.js";

const buttonStyle = {
  button: {
    minHeight: "auto",
    minWidth: "auto",
    color: "#FFFFFF",
    boxShadow:
      "0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12)",
    border: "none",
    borderRadius: "30px",
    position: "relative",
    padding: "12px 30px",
    margin: ".3125rem 1px",
    fontSize: "12px",
    fontWeight: "700",
    textTransform: "uppercase",
    willChange: "box-shadow, transform",
    transition:
      "box-shadow 0.2s cubic-bezier(0.4, 0, 1, 1), background-color 0.2s cubic-bezier(0.4, 0, 0.2, 1)",
    lineHeight: "1.42857143",
    textAlign: "center",
    whiteSpace: "nowrap",
    verticalAlign: "middle",
    touchAction: "manipulation",
    cursor: "pointer",
    "&:hover, &:focus": {
      color: "#FFFFFF",
      backgroundColor: "#1f1f1f",
      boxShadow:
        "0 14px 26px -12px rgba(0, 0, 0, 0.42), 0 4px 23px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2)"
    },
    "& i": {
      paddingRight: 10
    }
  },
  fullWidth: {
    width: "100%"
  },
  levarButton: {
    backgroundColor: levarBtnColor,
    boxShadow:
      "0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12)",
    "&:hover,&:focus": {
      backgroundColor: "#231f20",
      boxShadow:
        "0 14px 26px -12px rgba(0, 0, 0, 0.42), 0 4px 23px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2)"
    }
  },
  tealButton: {
    backgroundColor: "#1398b7",
    boxShadow:
      "0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12)",
    "&:hover,&:focus": {
      backgroundColor: "#231f20",
      boxShadow:
        "0 14px 26px -12px rgba(0, 0, 0, 0.42), 0 4px 23px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2)"
    }
  },
  transparent: {
    "&,&:focus,&:hover,&:visited": {
      color: "inherit",
      background: "transparent",
      boxShadow: "none"
    }
  },
  lg: {
    padding: "1.125rem 2.25rem",
    fontSize: "0.875rem",
    lineHeight: "1.333333"
  },
  md: {
    padding: "0.65rem 1.65rem",
    fontSize: "1rem",
    lineHeight: "1"
  },
  sm: {
    padding: "0.40625rem 1.25rem",
    fontSize: "0.8875rem",
    lineHeight: "1.5",
  },
  block: {
    width: "100% !important"
  },
  link: {
    "&,&:hover,&:focus": {
      backgroundColor: "transparent",
      color: "#999999",
      boxShadow: "none"
    }
  }
};

export default buttonStyle;
