import {
  container,
  title,
  copy,
  subtitle,
  subContainer,
  mainRaised } from "../material-react.js";

const aboutPageStyle = {
  container: {
    zIndex: "12",
    color: "#FFFFFF",
    ...container
  },
  subContainer: {
    ...subContainer,
    padding: "2rem 0",
  },
  title: {
    ...title,
    display: "inline-block",
    position: "relative",
    marginBottom: "0",
    minHeight: "32px",
    color: "#FFFFFF",
    textDecoration: "none"
  },
  subtitle: {
    ...subtitle,
    fontSize: "1.313rem",
  },
  containerTitle: {
    color: "#4c4c4c",
    textTransform: "uppercase",
    fontSize: "1.75em",
    fontWeight: "300",
  },
  copy: {
    ...copy,
    color: "#4c4c4c",
    margin: "0",
    fontWeight: "300",
    fontSize: "1em",
    lineHeight: "1.45em"
  },
  employee: {
    height: "250px",
    backgroundSize: "cover",
    backgroundPosition: "top center",
    padding: "1em",
    borderRadius: "4px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-end",
    marginBottom: "1em"
  },
  name: {
    margin: "0",
    fontWeight: "bold",
    color: "#ffffff",
    paddingBottom: "5px;",
    textShadow: "1px 1px 9px black"
  },
  job: {
    margin: "0",
    color: "#ffffff",
    textTransform: "uppercase",
    textShadow: "1px 1px 9px black"
  },
  main: {
    background: "#FFFFFF",
    position: "relative",
    zIndex: "3"
  },
  mainRaised: {
    ...mainRaised,
    boxShadow:
      "0 16px 24px 2px rgba(0, 0, 0, 0.14), 0 6px 30px 5px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2)"
  }
};

export default aboutPageStyle;
