import React from 'react';
import { makeStyles } from "@material-ui/core/styles";
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';

const useStyles = makeStyles((theme) => ({
    container: {
        paddingTop: '2em',
        paddingBottom: '2em',

     },
     paper: {
         padding: theme.spacing(2),
         height: '100%',
         position: 'relative',
 
     },
     content: {
        textAlign: 'center',
     },
     image: {
        maxHeight: '900px',
        maxWidth: '900px',
        height: '100%',
        width: '100%',
     }
}));

export default function LargeImage(props) {
    const classes = useStyles();
    
    return (
        <Container className={classes.container}>
            <Grid container direction="row" justify="center" alignItems="center" spacing={3}>
                {props.content.image_max_height &&
                <Grid item xs={12} className={classes.content}>
                    <img src={props.content.image} style={{ maxHeight: `${props.content.image_max_height}px`, maxWidth: `${props.content.image_max_width}px`, height: '100%', width: '100%' }} alt={'bigImage'} />
                </Grid>
                }
            </Grid>
        </Container>
    )
}
