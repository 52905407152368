import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import texture from "../../../assets/images/home/stepTexture.png";
import GridItem from "../../../components/Grid/GridItem.js";
import GridContainer from "../../../components/Grid/GridContainer.js";
import WebsiteDialog from "../../../components/Dialog/WebsiteDialog";

const styles = {
  threeBoxes: {
    position: "relative",
    top: '-80px',
    zIndex: 9,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-around',
    width: '100%',
    maxWidth: '1300px',
    padding: '0 2em',
    margin: 'auto',
    flexDirection: 'column',
    "@media (min-width: 870px)": {
      padding: '0 4em',
      flexDirection: 'row',
    },
  },
  box: {
    flex: 1,
    height: '300px',
    borderRadius: '3px',
    boxShadow: '1px 3px 6px 0px #15151540',
    position: 'relative',
    padding: '1em',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    background: `linear-gradient( to top, rgba(255,255,255, .65), rgba(255,255,255, .6)),url(${texture})`,
    "@media (max-width: 870px)": {
      margin: '0 0 2em 0',
      width: '100%',
      height: 'auto',
    },
    '&:nth-child(2)': {
      margin: '0 4%',
      "@media (max-width: 870px)": {
        margin: '0 0 2em 0'
      }
    },
    '& > h3': {
      fontSize: '1.5em',
      margin: 0,
    },
    '& > p': {
      fontSize: '1.15em',
      fontWeight: 300,
    }
  },
  gridItem: {
    display: 'flex',
    alignItems: 'stretch',
    '& ul': {

    }
  },
  rowCalloutContainer: {
    width: '100%',
    height: '100px',
    display: 'flex',
    padding: '20px',
    background: 'linear-gradient(to right, rgba(0,51,78,.95) 0%, rgba(255,52,81,.90) 100%)',
    alignItems: 'center',
    backgroundSize: 'cover',
    justifyContent: 'center',
    marginTop: '20px',
  },
  // rowCallOut: {

  //   alignItems: 'center',
  //   display: 'inline-flex',
  //   '& a': {
  //     color: '#ffffff',
  //     fontSize: '30px',
  //     lineHeight: '1.2',
  //     textAlign: 'center',
  //     display: 'flex',
  //     alignItems: 'center',
  //     textDecoration: 'none',
  //     fontWeight: 'bold',
  //     opacity: 1,
  //     transition: 'all .3s ease-in-out',
  //     "&:hover, &:focus": {
  //       color: "#FFFFFF",
  //       opacity: .65
  //     },
  //     "@media (min-width: 576px)": {
  //       fontSize: '55px',
  //     },
  //   },
  //   '& svg': {
  //     fontSize: 30,
  //     marginRight: 10,
  //     "@media (min-width: 576px)": {
  //       fontSize: '55px',
  //     },
  //   }
  // }
};

const useStyles = makeStyles(styles);

const ThreeBoxes = () => {
  const classes = useStyles();
  return (
   <div className={classes.root}>
      <div className={classes.threeBoxes}>
        <div className={classes.box}>
          <h3>Engage Your Customers</h3>
          <p>
            Give your customers an immersive
            shopping experience that keeps
            them on your site for longer.
          </p>
        </div>
        <div className={classes.box}>
          <h3>Bring Products to Life</h3>
          <p>
            Reduce the imagination gap by
            allowing your customers to see
            the product in their environment,
            increasing buyer confidence.
          </p>
        </div>
        <div className={classes.box}>
          <h3>Build Brand Awareness</h3>
          <p>
            Use AR photos in your marketing
            to make your brand stand out.
          </p>
        </div>
      </div>
      <div className={classes.rowCalloutContainer}>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12} className={classes.rowCallOut}>
            <WebsiteDialog 
                  buttonText={'Book A Demo'} 
                  button_type={'custom'}
                  size={'h3'}
                  custom_code={<i style={{ paddingLeft: '20px'}} className="fad fa-rocket-launch"/>}
                  script={"https://assets.calendly.com/assets/external/widget.js"}
                  url={"https://calendly.com/d/dhj-gvd-qzm/levar-demo"} 
            />
            {/* <a href="https://meetings.hubspot.com/daniel-esrig/learnmore"
               target="_blank"
               rel="noopener noreferrer">
               Book A Demo <i style={{ paddingLeft: '20px'}} className="fad fa-rocket-launch"/>
            </a> */}
          </GridItem>
        </GridContainer>
      </div>
  </div>

  );
}


export default ThreeBoxes;
