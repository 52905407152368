import React from 'react';
import { createGAevent, pageview } from "../../services/googleAnalytics";
// MUI Components
import { useScript } from "../../services/hooks";
import { makeStyles } from '@material-ui/core/styles';
import Button from "../../components/CustomButtons/Button.js";
import Grid from '@material-ui/core/Grid';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import Typography from '@material-ui/core/Typography';


const useStyles = makeStyles((theme) => ({
    dialogContent: {
        overflowX: 'hidden',
        overflowY: 'hidden',
    },
    dialogTitle: {
        display: 'flex',
        justifyContent: 'left',
    },
    iframe: {
        height: "100%",
        width: "100%",
        minHeight: "700px",
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        border: 0,
        overflow: 'auto'
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
    },
    rowCallOut: {
        cursor: 'pointer',
        alignItems: 'center',
        display: 'inline-flex',
        color: '#ffffff',
        fontSize: '30px',
        lineHeight: '1.2',
        textAlign: 'center',
        textDecoration: 'none',
        fontWeight: 'bold',
        opacity: 1,
        transition: 'all .3s ease-in-out',
        "&:hover, &:focus": {
            color: "#FFFFFF",
            opacity: .65
        },
        "@media (min-width: 576px)": {
            fontSize: '55px',
        },
        '& svg': {
          fontSize: 30,
          "@media (min-width: 576px)": {
            fontSize: '55px',
          },
        }
    },

}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function WebsiteDialog(props) {
  const classes = useStyles();

  const [open, setOpen] = React.useState(false);

  useScript(props.script);

  const handleClickOpen = async() => {
    createGAevent({ action: "click", category: "Conversion", label: "modal_click", value: "open" });
    setOpen(true);
  };

  const handleClose = async() => {
    createGAevent({ action: "click", category: "Conversion", label: "modal_click", value: "close" });
    setOpen(false);
  };
/*
<!-- Start of Meetings Embed Script -->
    <div class="meetings-iframe-container" data-src="https://meetings.hubspot.com/daniel-esrig/learnmore?embed=true"></div>
    
  <!-- End of Meetings Embed Script -->

*/
  return (
    <React.Fragment>
        { props.button_type === 'custom' ? (
            <div className={classes.rowCallOut} onClick={handleClickOpen}>
                <Typography variant={props.size}>{props.buttonText}</Typography>
                { props.custom_code && props.custom_code }
            </div>
        ) : (
            <Button disabled={props.disabled} color="levarButton" size="sm" onClick={handleClickOpen}>
                {props.buttonText}
            </Button>
        )}

        <Dialog
            fullWidth={true}
            TransitionComponent={Transition}
            maxWidth={"lg"}
            open={open}
            onClose={handleClose}
            aria-labelledby="max-width-dialog-title"
            disableScrollLock={true}
        >
            <DialogTitle id="max-width-dialog-title" >
                <Grid container direction="row" className={classes.dialogTitle}> 
                    <Grid item xs={6}>
                        <Typography variant={'h6'}>{props.buttonText}</Typography>
                    </Grid>
                    <Grid item xs={6} className={classes.closeButton}>
                        <Button color="levarButton" size="sm" onClick={handleClose}>X</Button>
                    </Grid>
                </Grid>
            </DialogTitle>
            <DialogContent className={classes.dialogContent}>

            { props.url && 
                <iframe
                    className={classes.iframe}
                    src={props.url}
                    title="Forms Frame"
                >
                    <p>Your browser does not support iframes.</p>
                </iframe>
            }

            </DialogContent>
        </Dialog>
    </React.Fragment>
  );
}