import React from 'react';
import { makeStyles } from "@material-ui/core/styles";
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme) => ({
    container: {
        paddingTop: '4em',
        paddingBottom: '4em',
        [theme.breakpoints.up('md')]: {
            paddingLeft: '7em',
            paddingRight: '7em'
        },
     },
     content: {
        textAlign: 'center',

     },
     headline: {
        fontWeight: 700,
        lineHeight: 1.2,
        margin: 0,
        marginBottom: 20,
        fontSize: 32,
        [theme.breakpoints.down('xs')]:{
            fontSize: 22,
        },
        // color: '#000000',
        // "&:before": {
        //     content: "' '",
        //     height: 5,
        //     width: 57,
        //     position: 'absolute',
        //     marginTop: '-1.5rem',
        //     background: "#ff3451"
        // }
     },
     typography: {
        paddingBottom: '5px',
        paddingTop: '5px'
     },
     name: {
        fontWeight: 600,
        paddingTop: '10px',
        color: 'grey',
     },
     bio: {
        fontStyle: 'italic',
        color: 'grey',
        fontSize: 18,
        [theme.breakpoints.down('xs')]:{
            fontSize: 14,
        },
     },
     comment: {
        color: '#000000',
        paddingBottom: '10px',
        fontSize: 18,
        // fontStyle: 'italic',
        [theme.breakpoints.down('xs')]:{
            fontSize: 18,
        },
     },
     icon: { 
        maxHeight: 175, 
        maxWidth: 175, 
        width: '100%', 
        height: '100%',
    },
}));

export default function Testimonial(props) {
    const classes = useStyles();

    return (
        <Container className={classes.container} spacing={3}>
            <Grid container direction="column" justify="center" alignItems="center" >
                <Grid item className={classes.content}>
                    { props.content.headline && 
                    <Typography variant={'h5'} className={classes.headline} gutterBottom>
                        {props.content.headline}
                    </Typography>
                    }
                    <Typography variant={'h6'} className={classes.comment} gutterBottom>
                        {props.content.text}
                    </Typography>
                    <Typography variant={'subtitle1'} className={classes.name}>
                        {props.content.name}
                    </Typography>
                    <Typography variant={'subtitle1'} className={classes.bio}>
                        {props.content.bio}
                    </Typography>
                    <img 
                        src={props.content.company_logo} 
                        alt="testimonialLogo" 
                        rel="noopener noreferrer"
                        className={classes.icon} 
                        onClick={() => {window.open(props.content.company_link)}} 
                    />
                </Grid>
            </Grid>
        </Container>
    )
}