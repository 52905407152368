import React, { Suspense} from "react";
import { Canvas } from "react-three-fiber";
import Controls from './Controls';
import Environment from './Environment';
import Model from './Model';
import Camera from './Camera';
import * as THREE from "three";

const Viewer = (props) => {

  return (
    <div className="Viewer">
      <Canvas
        gl={{ antialias: true }}
        colorManagement={false}
        pixelRatio={window.devicePixelRatio}
        resize={undefined}
        onCreated={({ gl }) => {
          gl.toneMapping = THREE.LinearToneMapping;
          gl.outputEncoding = THREE.sRGBEncoding;
          gl.toneMappingExposure = 1;
        }}
        style={{ background: 'radial-gradient(circle, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 50%, rgba(235,235,235,1) 100%)', borderRadius: "3px" }}>
        <Camera />
        <Controls />
        <Suspense fallback={null}>
          <Model viewer_url={props.viewer_url}/>
        </Suspense>
        <Environment />
      </Canvas>
    </div>
  )
}

export default Viewer;
