import React, { useState, useEffect } from 'react';
import classNames from "classnames";
import { makeStyles } from "@material-ui/core/styles";
import { Link } from 'react-router-dom';
import butter from '../../butter-client';
import BlogLink from "./Sections/BlogLink";
import Header from "../../components/Header/Header";
import Parallax from "../../components/Parallax/Parallax.js";
import GridContainer from "../../components/Grid/GridContainer.js";
import GridItem from "../../components/Grid/GridItem.js";
import styles from "../../assets/jss/views/aboutPageStyle.js";
const useStyles = makeStyles(styles);

const BlogHome = (props) => {
  const classes = useStyles();
  const [blogContent, setBlogContent] = useState([])

  useEffect(() => {
    butter.post.list({page: 1, page_size: 30}).then(function(response) {
        // console.log(response)
      setBlogContent(response.data.data)
    });
  }, [])

  return (
    <div className="Blog Page-Content">
      <Header
        color="transparent"
        fixed
        changeColorOnScroll={{
          height: 150,
          color: "dark"
        }}
      />
      <Parallax small filter image={require("../../assets/images/blog-bg_new.jpg")}>
        <div className={classes.container}>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <h1 className={classes.title}>Levar Blog.</h1>
            </GridItem>
          </GridContainer>
        </div>
      </Parallax>
      <div className={classNames(classes.main, classes.mainRaised)}>
        <div className={classes.subContainer}>
          <GridContainer>
          {
            blogContent.length > 0 &&
            blogContent.map((post, key) => {
              return (
              <GridItem key={key} xs={12} sm={6} md={6}>
                <Link to={`/blog/posts/${post.slug}`}>
                  <BlogLink {...post} />
                </Link>
              </GridItem>
              )
            })
          }
          </GridContainer>
        </div>
      </div>
    </div>
  )
}

export default BlogHome;
