import React from 'react';
import { makeStyles } from "@material-ui/core/styles";

import GridContainer from "../../../components/Grid/GridContainer.js";
import GridItem from "../../../components/Grid/GridItem.js";
import Button from "../../../components/CustomButtons/Button.js";
import WebsiteDialog from "../../../components/Dialog/WebsiteDialog";
import styles from "../../../assets/jss/views/homePageStyle.js";

const { REACT_APP_DEMO_STORE_URL } = process.env;
const useStyles = makeStyles(styles);



const FeatureRowGradient = () => {
  const classes = useStyles();

  return (
    <div className={classes.featureRowGradient}>
      
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <h2 className={classes.featureRowTitle}>
            Now's the Time to Upgrade <br/> Your Shopping Experience
          </h2>
          <section className={classes.buttonGroup}>
            <Button
              color="levarButton"
              size="sm"
              href={REACT_APP_DEMO_STORE_URL}
              target="_blank"
              rel="noopener noreferrer"
            >
              E-Commerce Store
            </Button>
            <Button
              color="levarButton"
              size="sm"
              href="/pricing"
            >
              Pricing
            </Button>
            <WebsiteDialog 
              buttonText={'Schedule a Demo '} 
              script={"https://assets.calendly.com/assets/external/widget.js"}
              url={"https://calendly.com/d/dhj-gvd-qzm/levar-demo"} 
            />
          </section>
        </GridItem>
      </GridContainer>
    </div>
  )
}

export default FeatureRowGradient;
