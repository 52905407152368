import React from 'react';
import { makeStyles } from "@material-ui/core/styles";
// Core Components
import GridContainer from "../../../components/Grid/GridContainer.js";
import GridItem from "../../../components/Grid/GridItem.js";
import { Divider } from '@material-ui/core';
// Images New photos 1000x1000 (300dpi)
import mikePhoto from '../../../assets/images/team/mike.jpg';
import trePhoto from '../../../assets/images/team/tre.jpg';
import vikPhoto from '../../../assets/images/team/vik.jpg';
import benCullyPhoto from '../../../assets/images/team/BenCully.jpg';
import danPhoto from '../../../assets/images/team/dan.jpg';
import benPhoto from '../../../assets/images/team/ben.jpg';
import noraPhoto from '../../../assets/images/team/nora.jpg';
import jamesPhoto from '../../../assets/images/team/james.jpg';
import sinaPhoto from '../../../assets/images/team/sina.jpg'
import brandonPhoto from '../../../assets/images/team/brandon.jpg';
import lindsayPhoto from '../../../assets/images/team/lindsay.jpg';
import kirstenPhoto from '../../../assets/images/team/kirsten.jpg';
import pearcePhoto from '../../../assets/images/team/pearce_tibbles.jpg';
import patrickPhoto from '../../../assets/images/team/patrick_bean.jpg';
import chrisPhoto from '../../../assets/images/team/chris_betancourt.jpg';
import taylorPhoto from '../../../assets/images/team/taylor_knox.jpg';
// Styles
import styles from "../../../assets/jss/views/aboutPageStyle.js";
const useStyles = makeStyles(styles);

const Team = () => {
    const classes = useStyles();

    return (
        <div className={classes.container}>
            <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                    <h2 className={classes.containerTitle}>The levAR Team</h2>
                    <p className={classes.copy}>
                        Our team is made up of people who commit themselves to
                        being driven and creative. We're a group that believes
                        in working together to solve hard problems and having
                        fun while doing it. We work every day to build a platform that
                        works for today's e-commerce businesses and brands and will
                        continue to provide value in the future.
                    </p>
                </GridItem>
            </GridContainer>
            <br/>
            <Divider />
            <br/><br/>
            <GridContainer>
                <GridItem xs={12} sm={6} md={4}>
                    <section className={classes.employee} style={{ backgroundImage: `url(${trePhoto})` }}>
                        <p className={classes.name}>Tre Lucas</p>
                        <p className={classes.job}>CEO</p>
                    </section>
                </GridItem>
                <GridItem xs={12} sm={6} md={4}>
                    <section className={classes.employee} style={{ backgroundImage: `url(${danPhoto})` }}>
                        <p className={classes.name}>Daniel Esrig</p>
                        <p className={classes.job}>CTO</p>
                    </section>
                </GridItem>
                <GridItem xs={12} sm={6} md={4}>
                    <section className={classes.employee} style={{ backgroundImage: `url(${mikePhoto})` }}>
                        <p className={classes.name}>Michael Krog</p>
                        <p className={classes.job}>Lead Developer</p>
                    </section>
                </GridItem>
                <GridItem xs={12} sm={6} md={4}>
                    <section className={classes.employee} style={{ backgroundImage: `url(${vikPhoto})` }}>
                        <p className={classes.name}>Vikram Bhaduri</p>
                        <p className={classes.job}>Growth Manager</p>
                    </section>
                </GridItem>
                <GridItem xs={12} sm={6} md={4}>
                    <section className={classes.employee} style={{ backgroundImage: `url(${kirstenPhoto})` }}>
                        <p className={classes.name}>Kirsten Brehmer</p>
                        <p className={classes.job}>Director of Marketing</p>
                    </section>
                </GridItem>
                <GridItem xs={12} sm={6} md={4}>
                    <section className={classes.employee} style={{ backgroundImage: `url(${jamesPhoto})` }}>
                        <p className={classes.name}>James Clark</p>
                        <p className={classes.job}>Director of Technical Art</p>
                    </section>
                </GridItem>
                <GridItem xs={12} sm={6} md={4}>
                    <section className={classes.employee} style={{ backgroundImage: `url(${noraPhoto})` }}>
                        <p className={classes.name}>Nora Mertz</p>
                        <p className={classes.job}>Content Writer</p>
                    </section>
                </GridItem>
                <GridItem xs={12} sm={6} md={4}>
                    <section className={classes.employee} style={{ backgroundImage: `url(${pearcePhoto})` }}>
                        <p className={classes.name}>Pearce Tibbles</p>
                        <p className={classes.job}>Director of Customer Success</p>
                    </section>
                </GridItem>
                <GridItem xs={12} sm={6} md={4}>
                    <section className={classes.employee} style={{ backgroundImage: `url(${sinaPhoto})` }}>
                        <p className={classes.name}>Sina Amini</p>
                        <p className={classes.job}>Full Stack Engineer</p>
                    </section>
                </GridItem>
                <GridItem xs={12} sm={6} md={4}>
                    <section className={classes.employee} style={{ backgroundImage: `url(${lindsayPhoto})` }}>
                        <p className={classes.name}>Lindsay Seichter</p>
                        <p className={classes.job}>3D Artist</p>
                    </section>
                </GridItem>
                <GridItem xs={12} sm={6} md={4}>
                    <section className={classes.employee} style={{ backgroundImage: `url(${benPhoto})` }}>
                        <p className={classes.name}>Ben Chapman</p>
                        <p className={classes.job}>SHADE Engineer</p>
                    </section>
                </GridItem>
                <GridItem xs={12} sm={6} md={4}>
                    <section className={classes.employee} style={{ backgroundImage: `url(${taylorPhoto})` }}>
                        <p className={classes.name}>Taylor Knox</p>
                        <p className={classes.job}>2D &amp; Graphic Designer</p>
                    </section>
                </GridItem>
                <GridItem xs={12} sm={6} md={4}>
                    <section className={classes.employee} style={{ backgroundImage: `url(${brandonPhoto})` }}>
                        <p className={classes.name}>Brandon Kennedy</p>
                        <p className={classes.job}>3D Artist</p>
                    </section>
                </GridItem>
                <GridItem xs={12} sm={6} md={4}>
                    <section className={classes.employee} style={{ backgroundImage: `url(${benCullyPhoto})` }}>
                        <p className={classes.name}>Ben Cully</p>
                        <p className={classes.job}>Full Stack Engineer</p>
                    </section>
                </GridItem>
                <GridItem xs={12} sm={6} md={4}>
                    <section className={classes.employee} style={{ backgroundImage: `url(${patrickPhoto})` }}>
                        <p className={classes.name}>Patrick Bean</p>
                        <p className={classes.job}>Implementations Engineer</p>
                    </section>
                </GridItem>
                <GridItem xs={12} sm={6} md={4}>
                    <section className={classes.employee} style={{ backgroundImage: `url(${chrisPhoto})` }}>
                        <p className={classes.name}>Chris Betancourt</p>
                        <p className={classes.job}>Business Development</p>
                    </section>
                </GridItem>

            </GridContainer>
        </div>
    )
}

export default Team;
