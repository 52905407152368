// import { container } from "../material-react.js";
import texture from "../../images/home/stepTexture.png"

const salesPageStyle = {
  main: {
    background: "#FFFFFF",
    position: "relative",
    zIndex: "3",
    width: '100%',
  },
  topBar: {
    width: '100%',
    height: '33px',
    background: '#00334e',
    '&::after': {
      content: "''",
      position: 'absolute',
      backgroundImage: `url(${texture})`,
      width: '100%',
      height: '200px',
      top: '33px',
      "@media (min-width: 570px)": { height: '300px' },
      "@media (min-width: 870px)": { height: '400px' },
      "@media (min-width: 960px)": { height: '500px' },
    }
  },
  pageContent: {

  },
  rowContent: {
    width: '100%',
    maxWidth: '1300px',
    padding: '0 2em',
    margin: 'auto',
    display: 'flex',
    "@media (min-width: 870px)": { padding: '0 4em', },
  },
  row_one: {
    marginBottom: '100px',
    margin: 'auto',
    '& img': {
      width: '100%',
      margin: 'auto',
      display: 'flex',
    },
    "@media (min-width: 870px)": { marginBottom: '200px', },
  },
  rowOneBackground: {
    width: '100%',
    height: '300px',
    backgroundPosition: 'top left',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    position: 'relative',
    boxShadow: '0px 1px 10px #0000007d',
    "@media (min-width: 570px)": { height: '400px' },
    "@media (min-width: 870px)": { height: '500px' },
    "@media (min-width: 960px)": { height: '600px' },
  },
  cta: {
    display: 'flex',
    zIndex: 9,
    background: '#fff',
    flexDirection: 'column',
    width: '275px',
    position: 'relative',
    right: 0,
    borderRadius: '2px',
    top: '4em',
    textAlign: 'center',
    padding: '2em',
    margin: 'auto',
    boxShadow: '0px 0px 5px 0px #00000063',
    "@media (min-width: 450px)": { right: '-1em', position: 'absolute', margin: 0 },
    '& h3': {
      fontSize: '1.6em',
      margin: 0,
      letterSpacing: '-1px',
      paddingBottom: '1em',
      zIndex: 9,
    },
    '& a': {
      zIndex: 9,
      width: '75%',
      margin: 'auto',
      background: "#2c70f5"
    },
    '&::before': {
      content: "''",
      width: '275px',
      height: '100%',
      left: 0,
      zIndex: 1,
      top: 0,
      opacity: .3,
      position: 'absolute',
      backgroundImage: `url(${texture})`,
    }
  },
  row_two: {
    marginBottom: '100px',
    margin: 'auto',
    position: 'relative',
    '@media (min-width: 870px)': { marginBottom: '200px' },
    '&::before': {
      content: "''",
      width: '5%',
      height: '0px',
      background: '#f4f4f4',
      left: 0,
      position: 'absolute',
      zIndex: 2,
      top: 0,
      "@media (min-width: 992px)": {
        height: '4px'
      },
    },
    '&::after': {
      content: "''",
      height: '0px',
      background: '-webkit-linear-gradient(left, rgba(255,52,81,.90) 0%, rgba(0,51,78,.95) 100%)',
      right: 0,
      position: 'absolute',
      zIndex: 2,
      top: '30%',
      '@media (min-width: 1120px)': { height: '4px', width: '15%' }
    }
  },
  listContent: {
    display: 'flex',
    flexDirection: 'column',
    alignContent: 'center',
    justifyContent: 'center',
    flexGrow: 1,
    maxWidth: '100%',
    paddingLeft: 0,
    zIndex: 9,
    '@media (min-width: 870px)': { maxWidth: '600px', paddingLeft: '3em', },
    '& h2': {
      fontSize: '3em',
      textAlign: 'left',
      margin: 0,
      lineHeight: 1,
      paddingBottom: '10px',
      '@media (min-width: 1120px)': { textAlign: 'center', },
    },
    '& p': {

    },
  },
  listRow: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  listIcon: {
    paddingRight: '1em',
    '& img': {
      width: '60px',
    }
  },
  imagery: {
    position: 'relative',
    '&::before': {
      content: "''",
      position: 'absolute',
      background: '-webkit-linear-gradient(top, rgba(255,52,81,1) 0%, rgba(0,51,78,1) 100%)',
      width: '4px',
      height: '35%',
      left: 0,
      top: '-5%',
      zIndex: 9,
      display: 'none',
      '@media (min-width: 870px)': { display: 'block', }
    },
    '&::after': {
      content: "''",
      background: `url(${texture})`,
      position: 'absolute',
      zIndex: 1,
      top: '50%',
      height: '90%',
      transform: 'translateY(-50%)',
      left: '20%',
      width: '300px',
      backgroundSize: 'contain',
      opacity: .75,
      display: 'none',
      '@media (min-width: 870px)': { width: '500px', left: '50%', display: 'block' }
    },
  },
  rowImage: {
    position: 'relative',
    width: '100%',
    zIndex: 3,
    boxShadow: '1px 1px 2px 1px #00000021',
    display: 'none',
    '@media (min-width: 870px)': { maxWidth: '400px', display: 'flex' },
    '@media (min-width: 960px)': { maxWidth: '550px' },
    '@media (min-width: 1120px)': { maxWidth: '550px', },
    '& img': {
      zIndex: 9,
      width: '100%',
    }
  },
  row_three: {
    position: 'relative',
    marginBottom: '100px'
  }
};

export default salesPageStyle;
