import React, { useEffect, useRef } from "react";
import { extend, useFrame, useThree } from 'react-three-fiber';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';

extend({ OrbitControls });

const Controls = () => {
  const { camera, gl, invalidate } = useThree();
  const controls = useRef();
  let model = { size: { x: 0.5611930931440647, y: 0.8727187514305121, z: 0.6210610003686308 } }

  useFrame(() => controls.current.update());
  useEffect(() => void controls.current.addEventListener('change', invalidate), [controls.current])

  return (
    <React.Fragment>
      <orbitControls
        ref={controls}
        args={[camera, gl.domElement]}
        enableZoom={true}
        enableDamping={true}
        autoRotate
        autoRotateSpeed={.5}
        dampingFactor={0.1}
        maxAzimuthAngle={2 * Math.PI}
        maxPolarAngle={2 * Math.PI}
        minPolarAngle={0}
        minDistance={model.size.x}
        maxDistance={(model.size.x + model.size.y + model.size.z) * .8}
        zoomSpeed={.5}
      />
    </React.Fragment>
  );
}

export default Controls;
