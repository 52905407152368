import React from 'react';
import { useHistory } from "react-router-dom";
import butter from '../../butter-client';
import Header from "../../components/Header/Header";
import PageTitle from "../../components/PageTitle";
import ParallaxGradient from "../../components/Parallax/ParallaxGradient.js";
import { makeStyles } from "@material-ui/core/styles";
// Material UI Components
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
// Butter levAR Components
import FeatureLeft from "./Sections/FeatureLeft";
import FeatureRight from "./Sections/FeatureRight";
import FeatureRightFadeBoxes from "./Sections/FeatureRightFadeBoxes";
import ThreeBoxes from "./Sections/ThreeBoxes";
import FullWidthCTA from "./Sections/FullWidthCTA";
import PartnerLogos from "./Sections/PartnerLogos";
import Testimonial from "./Sections/Testimonial";
import Statement from "./Sections/Statement";
import LargeImage from "./Sections/LargeImage";
import ViewerIframe from "./Sections/ViewerIframe";
import Newsletter from "./Sections/Newsletter";
import HeroCustom from "./Sections/HeroCustom";
import VideoPlayer from "./Sections/VideoPlayer";

import { Helmet } from 'react-helmet'

const useStyles = makeStyles((theme) => ({
    root: {
        zIndex: 3,
        position: 'relative',
        background: '#FFFFFF',
    },
    main: {
        zIndex: 3,
        position: 'relative',
        background: '#FFFFFF',
        padding: '0em 0em 2em 0em'
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
        position: 'relative',
        background: '#202020',
        height: '85vh',
        marginTop: 64,
    },
}));

export default function Template(props) {
    const { match: { params } } = props;
    const history = useHistory();
    const [page, setPage] = React.useState(null)
    const classes = useStyles();

    React.useEffect(() => {
        butter.page.retrieve('*', params.type).then(function(resp) {
            // console.log(resp.data)
            setPage(resp.data)
        }).catch(function(resp) {
            console.log(resp)
            history.push("/home");
        });
    }, [params]);

    const render_levar_component = (type, fields, key) => {
        switch (type) {
            case 'feature_left':
                return <FeatureLeft content={fields} key={key} />
            case 'feature_right':
                return <FeatureRight content={fields} key={key} />
            case 'full_width_cta':
                return <FullWidthCTA content={fields} key={key} />
            case 'three_boxes':
                return <ThreeBoxes content={fields} key={key} />
            case 'partners_logos':
                return <PartnerLogos content={fields} key={key} />
            case 'testimonial':
                return <Testimonial content={fields} key={key} />
            case 'centered_statement':
                return <Statement content={fields} key={key} />
            case 'large_image':
                return <LargeImage content={fields} key={key} />
            case 'viewer_iframe':
                return <ViewerIframe content={fields} key={key} />
            case 'email_newsletter':
                return <Newsletter content={fields} key={key} />
            case 'three_box_fade':
                return <FeatureRightFadeBoxes content={fields} key={key} />
            case 'video_link_player':
                return <VideoPlayer content={fields} key={key} />
            default:
                return null;
        }
    };

    // if(!page) return (
    //     <Backdrop className={classes.backdrop} open={true}>
    //         <CircularProgress color="inherit" />
    //     </Backdrop>
    // );

    return (
        <div className={classes.root}>
            { page &&
                <Helmet>
                    <title>{page.data.fields.seo_title}</title>
                    <meta property='og:title' content={page.data.fields.seo_title} />
                </Helmet>
            }

            <Header color="transparent" fixed changeColorOnScroll={{ height: 150, color: "dark" }} />
            
            { !page ? (
                <Backdrop className={classes.backdrop} open={true}>
                    <CircularProgress color="inherit" />
                </Backdrop>
            ) : (
                page.data.fields.hero_custom_checkbox ? (
                    <HeroCustom content={page.data.fields.hero_custom_component} />
                ) : (
                    <ParallaxGradient
                        medium
                        filter
                        image={page.data.fields.hero_default_component.image}
                    >
                        <PageTitle title={page.data.fields.hero_default_component.title} />
                    </ParallaxGradient>
                )
            )
                
            }
            
            { page &&   
                <div className={classes.main}>
                    { 
                        page.data.fields.levar_content.map((row, key) => {
                            return render_levar_component(row.type, row.fields, key)
                        })
                    }
                </div>
            }
        </div>
    );
}
