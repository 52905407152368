import React, { useEffect, useState } from 'react';
import { makeStyles } from "@material-ui/core/styles";
import styles from "../../../assets/jss/views/homePageStyle.js";
const useStyles = makeStyles(styles);

const CONSTANTS = {
  DELETING_SPEED: 100,
  TYPING_SPEED: 100,
}

const TypeWriter = ({ currText, clearText }) => {
  const classes = useStyles();
  const [state, setState] = useState({
    text: "",
    message: "",
    isDeleting: false,
    loopNum: 0,
    typingSpeed: CONSTANTS.TYPING_SPEED,
  });

  useEffect(() => {
    setState(cs => ({
      ...cs,
      message: currText
    }));
  }, [currText])

  useEffect(() => {
    setState(cs => ({
      ...cs,
      isDeleting: true
    }));
  }, [clearText])

  useEffect(() => {
    let timer = "";
    const handleType = () => {
      setState(cs => ({
        ...cs,
        text: getCurrentText(cs),
        typingSpeed: getTypingSpeed(cs)
      }));
      timer = setTimeout(handleType, state.typingSpeed);
    };
    handleType();
    return () => clearTimeout(timer);
  }, [state.isDeleting, state.typingSpeed]);

  useEffect(() => {
    if (!state.isDeleting && state.text === state.message) {
      // when text is building wait 4 seconds // then set to delete
      // setTimeout(() => {
        // setState(cs => ({
        //   ...cs,
        //   isDeleting: true
        // }))
      //   // console.log('in delete', state.text);
      // }, 3000);
    } else if (state.isDeleting && state.text === "") {
      setState(cs => ({
        ...cs,
        isDeleting: false
      }));
    }
  }, [state.text, state.message, state.isDeleting]);

  function getCurrentText(currentState) {
    return currentState.isDeleting
      ? currentState.message.substring(0, currentState.text.length - 1)
      : currentState.message.substring(0, currentState.text.length + 1);
  }

  function getTypingSpeed(currentState) {
    return currentState.isDeleting
      ? CONSTANTS.TYPING_SPEED
      : CONSTANTS.DELETING_SPEED;
  }

  return (
    <h1 className={classes.heroTitle}>
      How will this<br/>look in my <span>{state.text || 'Backyard?'}</span>
      <span id="cursor" />
    </h1>
  );
}

export default TypeWriter;
