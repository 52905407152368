import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import Slide from '@material-ui/core/Slide';
import Button from '@material-ui/core/Button';
import CloseIcon from '@material-ui/icons/Close';
import { makeStyles } from '@material-ui/core/styles';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1
    },
    dialog: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center'
    },
    dialogContent: {
        position: 'relative',
        overflow: 'hidden',
        width: '100%',
        paddingTop: '56.25%' /* 16:9 Aspect Ratio (divide 9 by 16 = 0.5625) */
    },
    contentContainer: {
        height: '100%',
        width: '100%',
    },
    buttonContainer: {
        position: 'absolute',
        top: 0,
        right: 0,
        marginRight: -10,
    },
    button: {
        '&:hover': {

          },
    },
    viewer_paper: {
        
    },
    iframeContainer: {
        flexDirection: 'column',
        height: '100%',
        width: '100%',
        padding: "10px 10px 10px 10px"
      },
    iframe: {
        [theme.breakpoints.up('md')]: {
            minHeight: 700,
            minWidth: 700,
        },
        [theme.breakpoints.down('sm')]: {
            minHeight: 400,
            minWidth: 400,
        },

        height: '100%',
        width: '100%',
        borderRadius: 3,
        border: 0
    }
}));

const ViewerDialog = (props) => {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <Dialog
                className={classes.dialog}
                maxWidth={'lg'}
                open={props.open}
                TransitionComponent={Transition}
                disableScrollLock={true}
                keepMounted
                aria-labelledby="meeting-dialog-slide-title"
                aria-describedby="meeting-dialog-slide-description"
            >
                <DialogContent className={classes.dialogContent}>
                    <div className={classes.contentContainer}>
                        <div className={classes.buttonContainer}>
                            <Button className={classes.button} onClick={props.setOpen}>
                                <CloseIcon />
                            </Button>
                        </div>
                        <div className={classes.iframeContainer}>
                            <iframe
                                className={classes.iframe}
                                src={props.viewerUrl}
                                title="Viewer"
                            >
                                <p>Your browser does not support iframes.</p>
                            </iframe>
                        </div>
                    </div>
                </DialogContent>
            </Dialog>
        </div>
    );
}

export default ViewerDialog;
