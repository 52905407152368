import React from 'react';
import { makeStyles } from "@material-ui/core/styles";
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme) => ({
    container: {
        paddingTop: '2em',
        paddingBottom: '2em'
    },
    paper: {
        padding: theme.spacing(2)
    },
    title: { 
        color: '#000000',
        margin: 0,
        fontSize: 28,
        [theme.breakpoints.down('xs')]:{
            fontSize: 22,
        },
        fontWeight: 700,
        lineHeight: 1.2,
        marginBottom: 20,
        borderBottom: 'solid 2px #f8f8f8',
        paddingBottom: 10,
    },
    logoContainer: {
        display: 'flex',
        justifyContent: 'center'

    },
    logo: { 
        maxHeight: '120px', 
        maxWidth: '120px', 
        width: '100%', 
        height: '100%'
    },
}));


export default function PartnerLogos(props) {
    const classes = useStyles();

    return (
        <Container className={classes.container}>
            <Paper className={classes.paper}>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <Typography className={classes.title} gutterBottom>
                            {props.content.title}
                        </Typography>
                    </Grid>
                </Grid>
                <Grid container direction="row" justify="space-around" alignItems="center" spacing={3}>
                    { props.content.logo_repeater.map((item, key) => 
                        <Grid item xs={3} key={key} className={classes.logoContainer}>
                            { item.logo && <img src={item.logo} className={classes.logo} alt={`partnerLogo${key}`}/>}
                        </Grid>
                    )}                    
                </Grid>
            </Paper>
        </Container>
    )
}