import React, { useState, useEffect } from 'react'
import classNames from "classnames";
import { Helmet } from 'react-helmet'
import { useScript } from '../../services/hooks';
import { makeStyles } from "@material-ui/core/styles";
import Header from "../../components/Header/Header";
import butter from '../../butter-client'
import Parallax from "../../components/Parallax/Parallax.js";
import GridContainer from "../../components/Grid/GridContainer.js";
import GridItem from "../../components/Grid/GridItem.js";
import styles from "../../assets/jss/views/aboutPageStyle.js";
const useStyles = makeStyles(styles);


const BlogPost = (props) => {
    const classes = useStyles();
    const [post, setPost] = useState([])
  
    useEffect(() => {
        const { match } = props
        butter.post.retrieve(match.params[0]).then(function(response) {
            setPost(response.data.data)
        });
    }, [props]);
  
    let url = "https://static.klaviyo.com/onsite/js/klaviyo.js?company_id=QcM7RU";
    useScript(url);

    return (
        <div className="BlogPost Page-Content">
            { (post && post.seo_title) && 
                <Helmet>
                    <title>{post.seo_title}</title>
                    <meta property='og:title' content={post.seo_title} />
                </Helmet>
            }
            <Header
                color="transparent"
                fixed
                changeColorOnScroll={{
                height: 150,
                color: "dark"
                }}
            />
            <Parallax small filter image={post.featured_image}>
                <div className={classes.container}>
                <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                    <h1 className={classes.title}>{post.title}</h1>
                    </GridItem>
                </GridContainer>
                </div>
            </Parallax>
            <div className={classNames(classes.main, classes.mainRaised)}>
                <div className={classes.subContainer}>
                <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                    <div className="BlogContent">
                        <div dangerouslySetInnerHTML={{ __html: post.body }} />
                    </div>
                    </GridItem>
                </GridContainer>
                </div>
            </div>
            {useScript(url)}
        </div>
    )
}

export default BlogPost;
