import React from 'react';
import ReactDOM from 'react-dom';
import { createTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/core/styles';

import { rootReducer } from './redux/reducers';
import { createStore, applyMiddleware } from 'redux';
import { Provider } from 'react-redux';

import './index.scss';
import App from './App';

import * as serviceWorker from './serviceWorker';

// import { BrowserRouter } from 'react-router-dom';
import { BrowserRouter as Router } from "react-router-dom";
import { composeWithDevTools } from 'redux-devtools-extension';
import thunk from 'redux-thunk';
import { ScrollToTop } from "./services/hooks";

const store = createStore(
  rootReducer,
  composeWithDevTools(applyMiddleware(thunk)),
);

const levarTheme = createTheme({
   typography: {
    fontFamily: `"Overpass", "Helvetica", "Arial", sans-serif`,
  },
  palette: {
    primary: {
      main: '#2196f3'
    },
    secondary: {
      main: '#404040'
    }
  }
});

const router = (
  <ThemeProvider theme={levarTheme}>
    <Provider store={store}>
      <Router>
          <ScrollToTop />
          <App />
      </Router>
    </Provider>
  </ThemeProvider>
)

ReactDOM.render(router, document.getElementById('root'));
serviceWorker.unregister();
