import React, { useState } from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// React Router
import { Link } from "react-router-dom";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Hidden from "@material-ui/core/Hidden";
import Drawer from "@material-ui/core/Drawer";
import Divider from "@material-ui/core/Divider";
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
// @material-ui/icons
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ReorderIcon from '@material-ui/icons/Reorder';
// core components
import styles from "../../assets/jss/components/headerStyles.js";
import levarIcon from '../../assets/images/levarlogo_whiteHoriz.png';
import WebsiteDialog from "../Dialog/WebsiteDialog.js";
import MobileCollapseMenu from './Sections/MobileCollapseMenu';

const { REACT_APP_DEMO_STORE_URL } = process.env;
const useStyles = makeStyles(styles);

export default function Header(props) {
    const { color, fixed, absolute } = props;
    const [mobileOpen, setMobileOpen] = useState(false);
    const classes = useStyles();
        
    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    const appBarClasses = classNames({
        [classes.appBar]: true,
        [classes[color]]: color,
        [classes.absolute]: absolute,
        [classes.fixed]: fixed
    });

    return (
        <AppBar className={appBarClasses}>
            <Toolbar className={classes.headerContainer}>
                <Link to="/"><img src={levarIcon} className={classes.logo} alt="levAR" /></Link>
                <Hidden smDown implementation="css">
                    <ul className="nav__menu">
                        <li className="nav__menu-item">
                            <Link to="/platform/overview">Platform</Link>
                        </li>
                        <li className="nav__menu-item">
                            {/* eslint-disable-next-line */}
                            <a href="#" className={classes.nav_menu_main_anchor}>
                                Integrations <ExpandMoreIcon />
                            </a>
                            <ul className="nav__submenu">
                                <li className="nav__submenu-item">
                                    <Link to="/integrations/shopify">Shopify</Link>
                                </li>
                                <li className="nav__submenu-item">
                                    <Link to="/integrations/klaviyo">Klaviyo</Link> 
                                </li>
                                <li className="nav__submenu-item">
                                    <Link to="/integrations/bigcommerce">BigCommerce</Link> 
                                </li>
                            </ul>
                        </li>
                        <li className="nav__menu-item">
                            {/* eslint-disable-next-line */}
                            <a href="#" className={classes.nav_menu_main_anchor}>
                                Use Cases <ExpandMoreIcon />
                            </a>
                            <ul className="nav__submenu">
                                <li className="nav__submenu-item">
                                    <Link to="/industries/furniture">Furniture</Link>
                                </li>
                                <li className="nav__submenu-item">
                                    <Link to="/industries/appliances">Appliances</Link>
                                </li>
                                <li className="nav__submenu-item">
                                    <Link to="/industries/rugs">Rugs</Link>
                                </li>
                                <li className="nav__submenu-item">
                                    <Link to="/industries/artwork">Artwork</Link>
                                </li>
                                <li className="nav__submenu-item">
                                    <Link to="/industries/social">Social</Link>
                                </li>
                            </ul>
                        </li>
                        <li className="nav__menu-item">
                            {/* eslint-disable-next-line */}
                            <a href="#" className={classes.nav_menu_main_anchor}>
                                Resources <ExpandMoreIcon />
                            </a>
                            <ul className="nav__submenu">
                                <li className="nav__submenu-item">
                                    <Link to="/ar-commerce">Why AR?</Link>
                                </li>
                                <li className="nav__submenu-item">
                                    <Link to="/resources/webar">WebAR</Link>
                                </li>
                                <li className="nav__submenu-item">
                                    <Link to="/resources/partners">Partners</Link>
                                </li>
                                <li className="nav__submenu-item">
                                    <Link to="/blog">Blog</Link>
                                </li>
                                <li className="nav__submenu-item">
                                    <Link to="/about">Team</Link>
                                </li>
                            </ul>
                        </li>
                        <li className="nav__menu-item">
                            <Link to="/pricing">Pricing</Link>
                        </li>

                    </ul>
                </Hidden>
                <Hidden smDown implementation="css">
                    <section className="levar-links desktop">
                        <div className="levar-dialog-demo">
                            <WebsiteDialog 
                                buttonText={"Request Demo"} 
                                button_type={'custom'}
                                custom_code={""}
                                size={'body1'}
                                script={"https://assets.calendly.com/assets/external/widget.js"}
                                url={"https://calendly.com/d/dhj-gvd-qzm/levar-demo"} 
                            />

                        </div>
                        <a href="https://app.levar.io/login" target="_blank" rel="noopener noreferrer" style={{ alignSelf: 'center'}}>
                            Login
                        </a>

                    </section>
                </Hidden>
                <Hidden mdUp>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        onClick={handleDrawerToggle}
                    >
                        <ReorderIcon />
                    </IconButton>
                </Hidden>
            </Toolbar>
            <Hidden mdUp implementation="js">
            <Drawer
                variant="temporary"
                anchor={"right"}
                open={mobileOpen}
                classes={{ paper: classes.drawerPaper }}
                onClose={handleDrawerToggle}
            >
                <List component="nav"
                    aria-labelledby="nested-list-subheader"
                    className={classes.mobile_root}
                >
                    <ListItem button component={Link} to="/platform/overview" className={classes.mobile_main_link}>
                        <ListItemText primary="Platform" />
                        <ChevronRightIcon />
                    </ListItem>
                    {/* <MobileCollapseMenu 
                        primary_text={"Platform"}
                        sub_links={
                            [
                                { text: "3D Player", to: "/page/3d-player" },
                                { text: "Custom Button", to: "/page/custom-button" },
                                { text: "Dynamic Popup", to: "/page/dynamic-popup" },
                                { text: "Product Renders", to: "/page/product-renders" },
                                { text: "Dashboard", to: "/page/dashboard" }
                            ]
                        }
                    /> */}
                    <Divider />
                    <MobileCollapseMenu 
                        primary_text={"Integrations"}
                        sub_links={
                            [
                                { text: "Shopify", to: "/integrations/shopify" },
                                { text: "Klayvio", to: "/integrations/klaviyo" },
                                { text: "BigCommerce", to: "/integrations/bigcommerce" }
                            ]
                        }
                    />
                    <Divider />
                    <MobileCollapseMenu 
                        primary_text={"Use Cases"}
                        sub_links={
                            [
                                { text: "Furniture", to: "/industries/furniture" },
                                { text: "Appliances", to: "/industries/appliances" },
                                { text: "Rugs", to: "/industries/rugs" },
                                { text: "Artwork", to: "/industries/artwork" },
                                { text: "Social", to: "/industries/social" }
                            ]
                        }
                    />
                    <Divider />
                    <MobileCollapseMenu 
                        primary_text={"Resources"}
                        sub_links={
                            [
                                { text: "Why AR?", to: "/ar-commerce" },
                                { text: "WebAR", to: "/resources/webar" },
                                { text: "Partners", to: "/resources/partners"},
                                { text: "Blog", to: "/blog" },
                                { text: "Team", to: "/about" },
                            ]
                        }
                    />
                    <Divider />
                    {/* <ListItem button component={Link} to="/ar-commerce" className={classes.mobile_main_link}>
                        <ListItemText primary="Why AR?" />
                        <ChevronRightIcon />
                    </ListItem> */}
                    <Divider />
                    <ListItem button component={Link} to="/pricing" className={classes.mobile_main_link}>
                        <ListItemText primary="Pricing" />
                        <ChevronRightIcon />
                    </ListItem>
                    <Divider />
                    <a href="https://dashboard.levar.io/"
                        target="_blank"
                        rel="noopener noreferrer"
                        className={classes.mobile_main_link}
                        >
                        <ListItem>
                            <ListItemText primary="Login" />
                            <ChevronRightIcon />
                        </ListItem>
                    </a>
                    <Divider />
                    <a href={REACT_APP_DEMO_STORE_URL}
                        target="_blank"
                        rel="noopener noreferrer"
                        className={classes.mobile_main_link}
                        >
                        <ListItem>
                            <ListItemText primary="Demo" />
                            <ChevronRightIcon />
                        </ListItem>
                    </a>
                    <Divider />
                </List>
            </Drawer>
        </Hidden>
        </AppBar>
    )
}
