import Home from "./views/Home/Home";
import ARCommerce from "./views/ARCommerce/ARCommerce";
import Sales from "./views/Sales";
import About from "./views/About/About";
import Pricing from "./views/Pricing/Pricing";
import BlogHome from "./views/BlogHome/BlogHome";
import BlogPost from "./views/BlogPost/BlogPost";
import Template from "./views/Template";

const routes = [
  {
    path: "/about",
    name: "About",
    component: About
  },
  {
    path: "/pricing",
    name: "Pricing",
    component: Pricing
  },
  {
    path: "/blog/posts/*",
    name: "Blog Posts",
    component: BlogPost
  },
  {
    path: "/blog",
    name: "Blog",
    component: BlogHome
  },
  {
    path: "/ar-commerce",
    name: "ARCommerce",
    component: ARCommerce
  },
  {
    path: "/sales",
    name: "Sales",
    component: Sales
  },
  {
    path: "/:page/:type",
    name: "Page",
    component: Template
  },
  {
    path: "/home",
    name: "Home",
    component: Home
  },
  {
    redirect: true,
    path: "/",
    to: "/home",
    name: "Home",
    component: Home
  }
];

export default routes;
