import React from 'react';
import { makeStyles } from "@material-ui/core/styles";
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';


const useStyles = makeStyles((theme) => ({
    container: {
        paddingTop: '4em',
        paddingBottom: '4em',
        [theme.breakpoints.up('md')]: {
            paddingLeft: '15em',
            paddingRight: '15em'
        },
    },
    content: {
        textAlign: 'center',
    },
    title: {
        fontWeight: 700,
        lineHeight: 1.2,
        margin: 0,
        marginBottom: 20,
        fontSize:42,
        [theme.breakpoints.down('xs')]:{
            fontSize: 32,
        },
    },
    text: {
        fontSize: 18,
        fontWeight: 400,
        lineHeight: 1.8
    },
}));

export default function Statement(props) {
    const classes = useStyles();

    return (
        <Container className={classes.container}>
            <Grid container direction="column" justify="center" alignItems="center" spacing={3}>
                <Grid item xs={12} className={classes.content}>
                    <Typography className={classes.title} variant={"h6"} gutterBottom>
                        {props.content.title}
                    </Typography>
                </Grid>
                <Grid item xs={12} className={classes.content}>
                    <Typography variant={"body1"} className={classes.text}>
                        {props.content.text}
                    </Typography>
                </Grid>
            </Grid>
        </Container>
    )
}