import React from 'react';
import { Link } from "react-router-dom";
const { REACT_APP_DEMO_STORE_URL } = process.env;

const Footer = () => {
  return (
    <div className="Footer">
        <section className="Footer-Top">
            <div className="Levar-SubLinksTop">
                <Link to="/about">Team</Link>
                <Link to="/blog">Blog</Link>
                <a href={REACT_APP_DEMO_STORE_URL} target="_blank" rel="noopener noreferrer">Demo</a>
            </div>
            <div className="Levar-SubLinks">
                <a href="mailto:info@levar.co">info@levar.co</a>
                <a href="https://app.termly.io/document/privacy-policy/f663a1fb-dac8-4ea0-ad4b-aed666a73007" target="_blank" rel="noopener noreferrer">Privacy Policy</a>
                <a href="https://app.termly.io/document/terms-of-use-for-ecommerce/e982814d-6e65-4c64-84fe-53b8e4c7ffe7" target="_blank" rel="noopener noreferrer">Terms of Service</a>
            </div>
            </section>
            <section className="Footer-Bottom">
            <div className="row">
                <h5>LEVAR HQ</h5>
                <p>Augmented Reality Solutions</p>
            </div>
            <div className="row">
                <p>
                222 W Merchandise Mart Plaza<br/>
                #1212, Chicago, IL 60654
                </p>
            </div>
            <div className="row">
                <p>2020 levAR, All Rights Reserved</p>
            </div>
        </section>
    </div>
  )
}

export default Footer;
