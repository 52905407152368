import {
  transition,
  boxShadow,
  drawerWidth,

} from "../material-react.js";

const headerStyle = {
    appBar: {
        display: "flex",
        border: "0",
        color: "#555",
        width: "100%",
        backgroundColor: "#fff",
        boxShadow:
        "0 4px 18px 0px rgba(0, 0, 0, 0.12), 0 7px 10px -5px rgba(0, 0, 0, 0.15)",
        transition: "all 150ms ease 0s",
        alignItems: "center",
        flexFlow: "row nowrap",
        justifyContent: "flex-start",
        position: "relative",
        zIndex: "unset"
    },
    absolute: {
        position: "absolute",
        zIndex: "1100"
    },
    fixed: {
        position: "fixed",
        zIndex: "1100"
    },
    headerContainer: {
        padding: "0 2em",
        flex: "1",
        alignItems: "center",
        justifyContent: "space-between",
        display: "flex",
        flexWrap: "nowrap",
        background: '#1f1f1f',
        "@media (min-width: 768px)": {
        padding: '0 4em',
        },
    },
    flex: {
        // flex: 1
    },
    appResponsive: {
        margin: "20px 10px"
    },
    logo: {
        maxWidth: "100px"
    },
    transparent: {
        // backgroundColor: "transparent !important",
        // boxShadow: "none",
        // paddingTop: "25px",
        color: "#FFFFFF"
    },
    dark: {
        color: "#FFFFFF",
        backgroundColor: "#1f1f1f !important",
        boxShadow:
        "0 4px 20px 0px rgba(0, 0, 0, 0.14), 0 7px 12px -5px rgba(33, 33, 33, 0.46)"
    },
    menuTypography: {
        color: "#FFFFFF",
        marginTop: '1px',
        display: 'flex'
    },

    drawerPaper: {
        border: "none",
        bottom: "0",
        backgroundColor: "#1f1f1f",
        transitionProperty: "top, bottom, width",
        transitionDuration: ".2s, .2s, .35s",
        transitionTimingFunction: "linear, linear, ease",
        width: drawerWidth,
        ...boxShadow,
        position: "fixed",
        display: "block",
        top: "0",
        height: "100vh",
        right: "0",
        left: "auto",
        visibility: "visible",
        overflowY: 'scroll',
        borderTop: "none",
        textAlign: "left",
        paddingRight: "0px",
        paddingLeft: "0",
        ...transition
    },
    nav_menu_main_anchor: {
        display: 'flex', 
        alignItems: 'center'
    },
    mobile_root: {

    },
    mobile_sub_root: {
        background: '#ffffff'
    },
    mobile_main_link: {
        color: "#ffffff",
        textDecoration: 'none',
        '& span': {
            fontSize: 18
        }
    },
    mobile_nested_link: {
        color: "#000000",
        '& span': {
            fontSize: 16
        }
    }
    // accordion: {
    //     background: 'none',
    //     boxShadow: 'none',
    //     borderBottom: 'solid 1px #5d5d5d',
    //     // paddingBottom: '.75em',
    //     marginBottom: '.75em',
    //     paddingLeft: '.25em',
    //     '&:before': {
    //         display: 'none',
    //     }
    // },
    // accordionSummary: {
    //     border: 'none',
    //     boxShadow: 'none',
    //     padding: 0,
    //     margin: 0
    // },
    // accordionTitle: {
    //     color: "#ffffff",
    //     fontWeight: 600,
    //     textTransform: 'uppercase',
    //     fontSize: '1.1em',
    //     letterSpacing: '.07em'
    // },
    // accordionItem: {
    //     color: 'white',
    //     cursor: 'pointer',
    //     textDecoration: 'none'
    // },
    // accordionContainer: {

    // },
    // accordionHeading: {
    //     fontSize: 10
    // },
    // accordionColumn: {
    //     flexBasis: "33.33%"
    // },
};

export default headerStyle;
