import React from 'react';
import classNames from "classnames";
import { makeStyles } from "@material-ui/core/styles";
import Header from "../../components/Header/Header";
import Parallax from "../../components/Parallax/Parallax.js";
import GridContainer from "../../components/Grid/GridContainer.js";
import GridItem from "../../components/Grid/GridItem.js";
import Button from "../../components/CustomButtons/Button.js";
import Team from "./Sections/Team";
import styles from "../../assets/jss/views/aboutPageStyle.js";
const useStyles = makeStyles(styles);

const About = () => {
  const classes = useStyles();

  return (
    <div className="About Page-Content">
      <Header
        color="transparent"
        fixed
        changeColorOnScroll={{
          height: 150,
          color: "dark"
        }}
      />
      <Parallax medium filter image={require("../../assets/images/team-bg.jpg")}>
        <div className={classes.container}>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <h1 className={classes.title}>About Us.</h1>
              <h3 className={classes.subtitle}>
                We're always looking for more passionate people who believe in our mission.
              </h3>
              <Button
                color="levarButton"
                size="md"
                href="https://levar.breezy.hr/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <i className="fas fa-search" />
                Careers
              </Button>
            </GridItem>
          </GridContainer>
        </div>
      </Parallax>
      <div className={classNames(classes.main, classes.mainRaised)}>
        <div className={classes.subContainer}>
          <Team />
        </div>
      </div>
    </div>
  )
}

export default About;
