import React, { useEffect, useCallback } from 'react';
import { useThree } from 'react-three-fiber';
import { PMREMGenerator } from 'three/src/extras/PMREMGenerator';
import { RGBELoader } from 'three/examples/jsm/loaders/RGBELoader';
import { HalfFloatType } from 'three';
import envhdr from "../../assets/hdri/photo_studio_01_1k.hdr"; // Environment (lights objects)

const Environment = React.memo(props => {
  const { gl, scene } = useThree();

  const generateEnvironment = useCallback(() => {
    const pmremGeneratorEnv = new PMREMGenerator(gl)
    const loader = new RGBELoader()
    loader.setDataType(HalfFloatType)
    pmremGeneratorEnv.compileEquirectangularShader();

    loader.load(envhdr, texture => {
      const textureData = pmremGeneratorEnv.fromEquirectangular(texture).texture
      scene.environment = textureData
      texture.dispose()
      pmremGeneratorEnv.dispose()
    })
  }, [scene, gl]);

  useEffect(() => {
    generateEnvironment();
  }, [generateEnvironment]);

  return null
});

export default Environment;
