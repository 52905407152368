import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Fade from 'react-reveal/Fade';
import Zoom from 'react-reveal/Zoom';

const styles = {
  sideArrow: {
    position: "absolute",
    top: 0,
    right: '2%',
    zIndex: 8,
    display: 'none',
    "@media (min-width: 960px)": {
      display: 'block'
    },
  },
  line: {
    height: 600,
    width: '6px',
    margin: 'auto',
    background: 'linear-gradient(rgba(0,51,78,.90) 0%, rgba(255,52,81,.90) 100%)',
  },
  point: {
  	borderLeft: '10px solid transparent',
  	borderRight: '10px solid transparent',
  	borderTop: '15px solid rgba(255,52,81,.90)',
  }
};

const useStyles = makeStyles(styles);

const SideArrow = () => {
  const classes = useStyles();
  return (
    <div className={classes.sideArrow}>
      <Fade top cascade delay={1000} duration={1000}>
        <div className={classes.line}></div>
      </Fade>
      <Zoom cascade delay={1500}>
        <div className={classes.point}></div>
      </Zoom>
    </div>
  );
}

export default SideArrow;
