import React from "react";
// @material-ui/core
import { makeStyles } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
// @material-ui/icons

// Custom
import Button from "../../../components/CustomButtons/Button.js";
import arrow from '../../../assets/images/sales/arrow.png';
// Other
import Zoom from 'react-reveal/Zoom';
import CountUp from 'react-countup';


const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    maxWidth: '100%',
  },
  container: {
      padding: '2em 2em 2em 2em',

  },
  paper: {
      padding: theme.spacing(2),
      background: 'none',
      boxShadow: 'none'
  },
  title: {
    fontWeight: 700,
    lineHeight: 1.2,
    margin: 0,
    marginBottom: 20,
    fontSize: 42,
    [theme.breakpoints.down('xs')]:{
        fontSize: 32,
    },
    color: '#000000',
    "&:before": {
        content: "' '",
        height: 5,
        width: 57,
        position: 'absolute',
        marginTop: '-1.5rem',
        background: "#ff3451"
    }
  },
  text: {
    fontSize: 18,
    fontWeight: 400,
    lineHeight: 1.8
  },
  item1: {
    padding: 4,
    order: 2,
    margin: 'auto',
    [theme.breakpoints.up('md')]: {
        order: 1,
    },
  },
  item2: {
      margin: 'auto',
      padding: 4,
      order: 1,
      [theme.breakpoints.up('md')]: {
          order: 2,
      },
  },
}));

const FeatureRightFadeBoxes = (props) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Container className={classes.container}>
        <Grid 
          spacing={3}
          container 
          direction="row"
          justify="center"
          alignItems="center"
        >
          <Paper className={classes.paper}>
            <div className="FeatureRightFadeBoxes-Content">
              <div className="RowFour page-row">
                <div className="RowContent">
                  <Grid item xs={12} md={6} className={classes.item1}>
                    <div className='copy'>
                      <section className="title">
                      { props.content.title && <h3 className={classes.title}>{props.content.title}</h3> }
                      </section>
                      { props.content.text && 
                        <Typography variant={'body1'} className={classes.text}>
                          {props.content.text}
                        </Typography>
                      }
                      { props.content.button_text && 
                        <Button
                          color="levarButton"
                          size="md"
                          href={props.content.button_link}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {props.content.button_text}
                        </Button>
                      }
                    </div>
                    </Grid>
                    <Grid item xs={12} md={6} className={classes.item2}>
                    <div className="box-container">
                      <div className="box-content">
                        <Zoom cascade delay={500}>
                        <div className="box-left">
                          <section className="box one">
                            <div className="arrow-container">
                              <img className="arrow down" src={arrow} alt="levAR" />
                            </div>
                            <div className="box-copy">
                              { props.content.box_one_text && <h3>{props.content.box_one_text}</h3> }
                              { props.content.box_one_stat && <h6><CountUp className="countup" duration={5} delay={1.5} end={props.content.box_one_stat} /><span>%</span></h6> }
                            </div>
                          </section>
                        </div>
                        <div className="box-right">
                          <section className="box two">
                            <div className="arrow-container">
                              <img className="arrow up" src={arrow} alt="levAR" />
                            </div>
                            <div className="box-copy">
                              { props.content.box_two_text && <h3>{props.content.box_two_text}</h3> }
                              { props.content.box_two_stat && <h6><CountUp className="countup" duration={5} delay={1.5} end={props.content.box_two_stat } /><span>%</span></h6> }
                            </div>
                          </section>
                          <section className="box three">
                            <div className="arrow-container">
                              <img className="arrow up" src={arrow} alt="levAR" />
                            </div>
                            <div className="box-copy">
                              {props.content.box_three_text && <h3>{props.content.box_three_text}</h3> }
                              { props.content.box_three_stat &&<h6><CountUp className="countup" duration={5} delay={1.5} end={props.content.box_three_stat} /><span>%</span></h6> }
                            </div>
                          </section>
                        </div>
                        </Zoom>
                      </div>
                    </div>
                  </Grid>
                </div>
              </div>
            </div>
          </Paper>
        </Grid>
      </Container>
    </div>
  );
}


export default FeatureRightFadeBoxes;






/*

const useStyles = makeStyles((theme) => ({
    root: {
      width: '100%',
      position: 'relative',
      maxWidth: '100%',
    },
    container: {
      paddingTop: '2em',
      paddingBottom: '2em'
    },
    paper: {
      padding: theme.spacing(2),
      background: 'none',
      boxShadow: 'none'
    },
    item1: {
      order: 2,
      [theme.breakpoints.up('md')]: {
          order: 1,
      },
    },
    item2: {
        order: 1,
        [theme.breakpoints.up('md')]: {
            order: 2,
        },
    },
    rowFour: {
      marginBottom: '100px',
      [theme.breakpoints.up('sm')]:{
        marginBottom: '200px',
      },
    },
    rowContent: {
      display: 'flex',
      flexDirection: 'column',
      [theme.breakpoints.up('md')]:{
        flexDirection: 'row'
      },
    },
    copy: {
      flex: 1,
    },
    title: {
      [theme.breakpoints.up('md')]:{
        paddingTop: '3em',
      },
      position: 'relative',
      paddingLeft: '1em',
      [theme.breakpoints.up('md')]:{
        width: '110%'
      },
        '&:before': {
          top: 0,
          left: 0,
          width: '4px',
          height: '100%',
          content: '',
          position: 'absolute',
          background: "linear-gradient(to top, rgba(0,51,78,1) 0%, rgba(255,52,81,1) 100%)"
        }
    },
    copyContent: {
      padding: '0 0px 2em 0px',
      [theme.breakpoints.up('sm')]:{
        padding: '0px 25px 0px 50px',
      },
    },

    arrowContainer: {
      display: 'flex',
    },
    arrowUp: {
      height: 'auto',
    },
    arrowDown: {
      height: 'auto',
      webkitTransform: "rotateX(180deg)",
      transform: "rotateX(180deg)",  
    },
    box: {
      background: 'blue',
      display: 'flex',
      justifyContent: 'space-between',
      boxShadow: '1px 1px 2px 1px #00000021',
      [theme.breakpoints.up('sm')]:{
        maxWidth: '300px',
        width: '100%'
      },
    },
    boxCopy: {
      background: 'blue',
      color: 'white',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      alignContent: 'flex-end',
    },
    boxTitle: {
      margin: 0,
      alignSelf: 'flex-end',
      textAlign: 'right',
      fontWeight: 400,
      paddingBottom: '10px',
      position: 'relative',
      marginBottom: '2em',
      '&:after': {
        content: "",
        position: 'absolute',
        width: '75%',
        height: '8%',
        borderRadius: '5px',
        background: '#bfbfbf',
        bottom: 0,
        right: 0,
      }
    },
    boxContainer: {
      position: 'relative',
      [theme.breakpoints.up('sm')]:{
        flex: 1.2,
      },
      '&:before': {
        content: "",
        background: 'url("../../assets/images/home/stepTexture.png")',
        position: 'absolute',
        zIndex: 1,
        backgroundSize: 'contain',
        opacity: .75,
        width: '75%',
        height: '75%',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
      }
    },
    boxContent: {
      display: 'flex',
      alignContent: 'center',
      justifyContent: 'center',
      position: 'relative',
      zIndex: 9,
      flexDirection: 'column',
      [theme.breakpoints.up('sm')]:{
        flexDirection: 'row',
      },
    },
    boxLeft: {
      display: 'flex',
      alignItems: 'center',
      flex: 1,
    },
    boxRight: {
      display: 'flex',
      flexDirection: 'column',
      flex: 1,
      [theme.breakpoints.up('sm')]:{
        alignItems: 'center',
        flex: 1.4,
      },
    },
    countupBoxText: {
      margin: 0,
      alignSelf: 'flex-end',
      textAlign: 'right',
      lineHeight: 1,
      '&:countup': {
        fontWeight: 700,
      },
      span: {
        fontWeight: 300,
      },
    },
    boxOne: {
      marginRight: '1em',
      width: '100%',
      padding: '1.5em',
      
    },
    arrowOne: {
      width: '50%',
      [theme.breakpoints.up('sm')]:{
        width: '50%',
      },
    },
    boxTwo: {
      padding: '1em',
      alignSelf: 'flex-start',
      marginBottom: '2em',
      marginTop: '2em',
      [theme.breakpoints.up('sm')]:{
        marginBottom: '1em',
        width: '50%'
      },
      [theme.breakpoints.up('md')]:{
        width: '75%',
      },
    },
    arrowTwo: {
      width: '65%',
      [theme.breakpoints.up('sm')]:{
        width: '50%',
      },
    },
    boxThree: {
      width: '100%',
      padding: '2em',
      justifyContent: 'space-between',
      [theme.breakpoints.up('sm')]:{
        width: '100%',
        maxWidth: '300px',
        padding: '1em',
      },
    },
    arrowThree: {
      width: '70%',
      [theme.breakpoints.up('sm')]:{
        width: '50%',
      },
    },
}));


const FeatureRightFadeBoxes = () => {
    const classes = useStyles();

    return (
        <div className={classes.root}>
          <Container className={classes.container}>
            <Grid 
              spacing={3}
              container 
              direction="row"
              justify="center"
              alignItems="center"
            >
                <Grid item xs={12} md={6} className={classes.item1}>
                  <div className={classes.copy}>
                    <section className={classes.title}>
                      <Typography variant={'h5'} className={classes.titleText}>Track + Measure<br/>Your ROI</Typography>
                    </section>
                    <div className={classes.copyContent}>
                      <Typography variant={'body1'} className={classes.copyText}>
                        With our powerful AR-Commerce Analytics
                        dashboard, you can see exactly how
                        your new shopping experience is
                        working harder for you.
                        <br/><br/>
                        We’re here to help you build a better
                        shopping experience, for both you
                        and your customers.
                      </Typography>
                      <Button
                        color="levarButton"
                        size="md"
                        href="https://meetings.hubspot.com/daniel-esrig/learnmore"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Start Today
                      </Button>
                    </div>
                  </div>
                  </Grid>
                  <Grid item xs={12} md={6} className={classes.item2}>
                  <div className={classes.boxContainer}>
                    <div className={classes.boxContent}>
                      <Zoom cascade delay={500}>
                        <div className={classes.boxLeft}>
                          <section className={classes.boxOne}>
                            <div className={classes.arrowContainer}>
                              <div className={classes.arrowOne}>
                                <img className={classes.arrowDown} src={arrow} alt="levAR" />
                              </div>
                            </div>
                            <div className={classes.boxCopy}>
                              <Typography variant={'h5'} className={classes.boxTitle}>Return<br/>Rates</Typography>
                              <Typography variant={'h6'} className={classes.countupBoxText}><CountUp className={classes.countup} duration={5} delay={1.5} end={40} /><span>%</span></Typography>
                            </div>
                          </section>
                        </div>
                        <div className={classes.boxRight}>
                          <section className={classes.boxTwo}>
                            <div className={classes.arrowContainer}>
                              <div className={classes.arrowTwo}>
                                <img className={classes.arrowUp} src={arrow} alt="levAR" />
                              </div>
                            </div>
                            <div className={classes.boxCopy}>
                              <Typography variant={'h5'} className={classes.boxTitle}>Customer<br/>Engagement</Typography>
                              <Typography variant={'h6'} className={classes.countupBoxText}><CountUp className={classes.countup} duration={5} delay={1.5} end={200} /><span>%</span></Typography>
                            </div>
                          </section>
                          <section className={classes.boxThree}>
                            <div className={classes.arrowContainer}>
                              <div className={classes.arrowThree}>
                                <img className={classes.arrowUp} src={arrow} alt="levAR" />
                              </div>
                            </div>
                            <div className={classes.boxCopy}>
                              <Typography variant={'h5'} className={classes.boxTitle}>Conversion<br/>Rates</Typography>
                              <Typography variant={'h6'} className={classes.countupBoxText}><CountUp className={classes.countup} duration={5} delay={1.5} end={27} /><span>%</span></Typography>
                            </div>
                          </section>
                        </div>
                      </Zoom>
                    </div>
                  </div>

                  </Grid>
            </Grid>
          </Container>
        </div>
    );
};

export default FeatureRightFadeBoxes;




*/