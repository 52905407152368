import Butter from 'buttercms'

const butter = Butter('1117f050461d322ff0d5901eb1a3b0b1634c9131')

export default butter


// // get all pages
// butter.page.list('*', "").then(function(resp) {
// console.log(resp)
// }).catch(function(resp) {
// console.log(resp)
// });