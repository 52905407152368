import React from 'react';
import { makeStyles } from "@material-ui/core/styles";

const styles = {
  pageTitleContainer: {
    maxWidth: '1300px',
    margin: 'auto',
    zIndex: 9,
    color: '#fff',
    padding: '0 2em',
    width: '100%',
    "@media (min-width: 870px)": {
      padding: '0 4em',
    },
  },
  pageTitle: {
    "&:before": {
      content: "''",
      position: 'absolute',
      background: 'linear-gradient(rgba(0,153,186,.90) 0%, rgba(255,52,81,.90) 100%)',
      left: 0,
      top: 0,
      height: '100%',
      width: '3px',
    },
    color: '#fff',
    position: 'relative',
    fontSize: '2.5em',
    fontWeight: 700,
    lineHeight: '1.1em',
    paddingLeft: '.5em',
    "@media (min-width: 420px)": {
      fontSize: '3em',
    },
    "@media (min-width: 576px)": {
      fontSize: '3em',
    },
    "@media (min-width: 768px)": {
      fontSize: '3em',
    },
    "@media (min-width: 992px)": {
      fontSize: '3.8em',
    },
    "@media (min-width: 1102px)": {
      fontSize: '4.5em',
    },
  }
};

const useStyles = makeStyles(styles);

const PageTitle = (props) => {
  const classes = useStyles();
  const { title } = props;

  return (
    <div className={classes.pageTitleContainer}>
      <h1 className={classes.pageTitle}>{title}</h1>
    </div>
  );
};

export default PageTitle;
