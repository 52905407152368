import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Container from '@material-ui/core/Container';
import ViewerDialog from '../../../components/Dialog/ViewerDialog';
import Button from "../../../components/CustomButtons/Button.js";

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        maxWidth: '100%',
    },
    container: {
        paddingTop: '2em',
        paddingBottom: '2em'
    },
    paper: {
        padding: theme.spacing(2),
        background: 'none',
        boxShadow: 'none'
    },
    imgPaper: {
        padding: theme.spacing(2),
        boxShadow: 'none'
    },
    feature_image: {
        width: '100%'
    },
    item1: {
        order: 2,
        [theme.breakpoints.up('md')]: {
            order: 1,
        },
    },
    item2: {
        order: 1,
        [theme.breakpoints.up('md')]: {
            order: 2,
        },
    },
    title: {
        fontWeight: 700,
        lineHeight: 1.2,
        margin: 0,
        marginBottom: 20,
        fontSize:42,
        [theme.breakpoints.down('xs')]:{
            fontSize: 32,
        },
        color: '#000000',
        "&:before": {
            content: "' '",
            height: 5,
            width: 57,
            position: 'absolute',
            marginTop: '-1.5rem',
            background: "#ff3451"
        }
    },
    text: {
        fontSize: 18,
        fontWeight: 400,
        lineHeight: 1.8
    },
    iframeContainer: {
        overflowX: 'hidden',
        overflowY: 'hidden'
    },
}));

export default function FeatureRight(props) {
    const classes = useStyles();
    const [dialogOpen, setDialogOpen] = React.useState(false);

    return (
        <div className={classes.root} style={{ background: `${props.content.background_color}`}}>
            <Container className={classes.container}>
                <Grid 
                    spacing={3}
                    container 
                    direction="row"
                    justify="center"
                    alignItems="center"
                >
                    <Grid item xs={12} md={6} className={classes.item1}>
                        <Paper className={classes.paper}>
                            { props.content.title && <h3 className={classes.title}>{props.content.title}</h3> }
                            { props.content.text && <p className={classes.text}>{props.content.text}</p> } 
                            { !props.content.viewer_modal && 
                                <Button
                                    color="levarButton"
                                    size="md"
                                    href={props.content.link_ref}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    {props.content.link_text}
                                </Button>
                            }
                            { props.content.viewer_modal &&
                                <Button
                                    color="levarButton"
                                    size="md"
                                    onClick={() => {setDialogOpen(true)}}
                                >
                                    {props.content.link_text}
                                </Button>

                            }
                            { dialogOpen && <ViewerDialog open={dialogOpen} viewerUrl={props.content.link_ref} setOpen={() => setDialogOpen(false)} /> }
                        </Paper>
                    </Grid>
                    <Grid item xs={12} md={6} className={classes.item2}>
                        <Paper className={classes.imgPaper}>
                            <img src={props.content.image} className={classes.feature_image} alt={props.content.title} />
                        </Paper>
                    </Grid>
                </Grid>
            </Container>
        </div>
    );
}