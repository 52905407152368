import React, { useEffect, useCallback } from "react";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import { useLoader, useThree } from 'react-three-fiber';
import * as THREE from "three";

const Model = (props) => {
  const url = props.viewer_url
  const glb = useLoader(GLTFLoader, url);
  const scene = glb.scene;

  const handleUpdate = useCallback(() => {

    const boundingBox = new THREE.Box3().setFromObject(scene); // glbbounding
    const size = boundingBox.getSize(new THREE.Vector3()); // glbboundingvec
  }, [scene]);

  useEffect(() => {
    handleUpdate();
  }, [handleUpdate])

  return <primitive object={scene} dispose={null} />
}

export default Model
