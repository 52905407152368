import { fetchOptions } from './fetchOptions';
const { REACT_APP_KLAVIYO_ENDPOINT } = process.env;

export const fetchData = async (url, body) => {
  try {
    const response = await fetch(url, body)
    if(!response.ok) { throw new Error(`Fetch Call Cannot Be Made`)}
    const data = await response.json()
    return data;
  } catch (error) {
    return error;
  }
}

export const fetchSetKlaviyo = async (data) => {
  const options = fetchOptions('POST', data);
  const url = `${REACT_APP_KLAVIYO_ENDPOINT}`;
  const response = await fetch(url, options);
  const jres = await response.json();
  return jres;
};