import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import Slide from '@material-ui/core/Slide';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const Video = ({ youtubeId, videoOpen, handleVideoOpen }) => {

  return (
    <div>
      <Dialog
        open={videoOpen}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => handleVideoOpen(false)}
        maxWidth={'md'}
        fullWidth={true}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogContent style={{ padding: '15px', background: '#292929' }}>
          <div
            className="video"
            style={{
              position: "relative",
              paddingBottom: "56.25%" /* 16:9 */,
              paddingTop: 25,
              height: 0
            }}
          >
            <iframe
              style={{
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%"
              }}
              title="levAR Demo"
              src={`https://www.youtube.com/embed/${youtubeId}`}
              frameBorder="0"
            />
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default Video;
