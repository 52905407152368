import React from 'react';
import classNames from "classnames";
import { makeStyles } from "@material-ui/core/styles";
import ParallaxGradient from "../../components/Parallax/ParallaxGradient.js";
import Header from "../../components/Header/Header";
import SideArrow from "../../components/SideArrow";
import PageTitle from "../../components/PageTitle";
import PricingContent from "./Sections/PricingContent";

import styles from "../../assets/jss/views/arCommercePageStyle.js";
const useStyles = makeStyles(styles);

export default function Pricing() {
    const classes = useStyles();

    return (
        <div className="Pricing Page-Content">
            <Header color="transparent" fixed changeColorOnScroll={{ height: 150, color: "dark" }} />
            <SideArrow />
            <ParallaxGradient
                medium
                filter
                image={require("../../assets/images/pricing-bg.jpg")}
                >
                <PageTitle title={'Powerful Visualization, Flexible Pricing.'} />
            </ParallaxGradient>
            <div className={classNames(classes.main)}>
                <PricingContent />
            </div>
        </div>
    )
}


