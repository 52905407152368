import React from 'react';
import classNames from "classnames";
import { makeStyles } from "@material-ui/core/styles";
import ParallaxGradient from "../../components/Parallax/ParallaxGradient.js";
import Header from "../../components/Header/Header";
import SideArrow from "../../components/SideArrow";
import PageTitle from "../../components/PageTitle";
import SalesContent from "./Sections/SalesContent";
import styles from "../../assets/jss/views/salesPageStyle.js";
const useStyles = makeStyles(styles);

export default function Sales() {
    const classes = useStyles();

    return (
        <div className="Sales Page-Content">
            <Header color="transparent" fixed changeColorOnScroll={{ height: 150, color: "dark" }} />
            <SideArrow />
            <ParallaxGradient
                medium
                filter
                image={require("../../assets/images/email/email-bg.jpg")}
            >
            <PageTitle title={'Your Product. Their Space. Before They Buy.'} />
            </ParallaxGradient>
            <div className={classNames(classes.main)}>
                <SalesContent />
            </div>
        </div>
    );
}
