import { container } from "../material-react.js";

const arCommercePageStyle = {
  container: {
    zIndex: "12",
    color: "#FFFFFF",
    ...container
  },
  heroTitle: {
    fontSize: '1.8em',
    fontWeight: '700',
    margin: '.5rem 0',
    lineHeight: 1,
    "@media (min-width: 420px)": {
      fontSize: '2em',
    },
    "@media (min-width: 576px)": {
      fontSize: '3em',
    },
    "@media (min-width: 768px)": {
      fontSize: '4em',
    },
    "@media (min-width: 992px)": {
      fontSize: '4.5em',
    },
  },
  main: {
    background: "#FFFFFF",
    position: "relative",
    zIndex: "3"
  },
  featureRowTitle: {
    color: '#ffffff',
    fontSize: '35px',
    lineHeight: '1.2',
    textAlign: 'center',
    margin: '10px 0',
    "@media (min-width: 576px)": {
      fontSize: '55px',
    },
  }
};

export default arCommercePageStyle;
