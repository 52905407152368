import React from 'react';
import { makeStyles } from "@material-ui/core/styles";
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import Divider from '@material-ui/core/Divider';
// import Button from '@material-ui/core/Button';
// import icon from '../../../assets/images/email/step2.png';

const useStyles = makeStyles((theme) => ({
    container: {
       paddingTop: '4em',
       paddingBottom: '4em'
    },
    paper: {
        padding: theme.spacing(2),
        height: '100%',
        position: 'relative',

    },
    title: { fontSize: 20, lineHeight: 1.8, fontWeight: 600},
    copy: { fontSize: 16, lineHeight: 1.8, paddingBottom: '30px' },
    link: {
        color: '#ff3451',
        textDecoration: 'none',
        fontSize: 16,
        position: 'absolute',
        bottom: 10,
        left: 20,
        opacity: 1,
        transition: 'all .3s ease',
        '&:hover': { opacity: .65 }
    }
}));

export default function ThreeBoxes(props) {
    const classes = useStyles();

    return (
        <Container className={classes.container}>
            <Grid container direction="row" justify="center" alignItems="stretch" spacing={5}>
                { props.content.three_box_repeater.map((box, key) => 
                    <Grid item xs={12} md={4} key={key}>
                        <Paper className={classes.paper}>
                            <Grid item xs={12}>
                                { (box.image && box.large_icon) && <img style={{ verticalAlign: 'middle', maxHeight: 60, maxWidth: 200, width: '100%', height: '100%', margin: "8px 0px 8px 0px" }} alt="icon" src={box.image} /> }
                                { (box.image && !box.large_icon) && <img style={{ verticalAlign: 'middle', maxHeight: 60, maxWidth: 60, width: '100%', height: '100%' }} alt="icon" src={box.image} /> }
                            </Grid>
                            <Grid item xs={12}>
                                { box.title && <Typography className={classes.title} variant={'h5'} noWrap={true} gutterBottom>{box.title}</Typography> }
                            </Grid>
                            <Grid item xs={12}>
                                { box.text && <Typography className={classes.copy} variant={'body1'} gutterBottom>{box.text}</Typography> }
                            </Grid>
                            <Grid item xs={12}>
                                { box.link_text && 
                                    <Link
                                        className={classes.link}
                                        href={box.link_ref}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        {box.link_text}
                                    </Link>
                                }
                            </Grid>
                        </Paper>
                    </Grid>
                )}
            </Grid>
        </Container>
    );
};

// direction="row" justify="center" alignItems="center"

/*
export default function ThreeBoxes() {
    const classes = useStyles();

    return (
        <Container
            className={classes.container}
        >
            <Grid container spacing={3}>
                <Grid item xs={12} md={4}>
                    <div className={classes.paper}>
                        <img src={icon} className={classes.icon} alt="icon" />
                        <Typography className={classes.title} noWrap={true} gutterBottom>Create ultra-specific segments</Typography>
                        <Typography className={classes.copy} gutterBottom>
                            Want to create an email campaign just for left-handed dentists in Nebraska? Go right ahead. Use as many conditions as you want to build detailed segments.
                        </Typography>
                        <a href="http://google.com" target="_blank" rel="noopener noreferrer" className={classes.link}>Segmentation</a>
                    </div>
                </Grid>
                <Grid item xs={12} md={4}>
                    <div className={classes.paper}>
                        <img src={icon} className={classes.icon} alt="icon" />
                        <Typography className={classes.title} noWrap={true} gutterBottom>Automate your marketing</Typography>
                        <Typography className={classes.copy} gutterBottom>
                            Make money while you sleep by automating welcome emails, abandoned cart reminders, and more. Then, hone your approach with built-in A/B testing.
                        </Typography>
                        <a href="http://google.com" target="_blank" rel="noopener noreferrer" className={classes.link}>Automation</a>
                    </div>
                </Grid>
                <Grid item xs={12} md={4}>
                    <div className={classes.paper}>
                        <img src={icon} className={classes.icon} alt="icon" />
                        <Typography className={classes.title} noWrap={true} gutterBottom>Measure what really matters</Typography>
                        <Typography className={classes.copy} gutterBottom>
                            Don’t just settle for opens and click rates. Find out the important stuff — like how much revenue you’re actually earning through your owned channels.
                        </Typography>
                        <a href="http://google.com" target="_blank" rel="noopener noreferrer" className={classes.link}>Reporting</a>
                    </div>
                </Grid>
            </Grid>
        </Container>
    );
}

*/

